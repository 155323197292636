<template>
  <button v-if="buttonStyle == 0" type="button" class="btn btn-primary" @click="showModal()">
    <font-awesome-icon icon="plus" /> Neues Dokument
  </button>

  <button v-else type="button" class="btn btn-primary btn-sm" title="Dokument bearbeiten" @click="showModal()">
    <font-awesome-icon icon="pen" />
  </button>

  <!-- Edit DokumentModal -->
  <div
    class="modal fade"
    :id="'editDokumentModal' + index"
    tabindex="-1"
    :aria-labelledby="'editDokumentModal' + index"
    aria-hidden="true"
    ref="editDokumentModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary py-2">
          <span v-if="editDokument.id == 0" class="modal-title my-auto" :id="'editDokumentModalLabel' + index">
            Neues Dokument
          </span>

          <span v-else class="modal-title my-auto" :id="'editDokumentModalLabel' + index">
            Dokument bearbeiten
          </span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'editDokumentModalBody' + index">
          <LoadingSpinner v-if="loading" />

          <form v-else @submit.prevent="saveDokument">

            <div class="mb-3">
              <label :for="'dokumentTitel-' + index" class="form-label fw-bold">Titel</label>
              <input type="text" class="form-control" :id="'dokumentTitel-' + index" v-model="editDokument.titel">
            </div>
            
            <div class="mb-3">
              <label :for="'dokumentKategorie-' + index" class="form-label fw-bold">Kategorie</label>
              <input type="text" class="form-control" :id="'dokumentKategorie-' + index" v-model="editDokument.kategorie">
            </div>

            <div class="mb-3">
              <label :for="'dokumentFileName-' + index" class="form-label fw-bold">Datei Name</label>
              <input type="text" class="form-control" :id="'dokumentFileName-' + index" v-model="editDokument.fileName" disabled>
            </div>

            <div class="mb-3">
              <label for="fileupload" class="form-label">Word-Vorlage (.dotx) auswählen</label>
              <input type="file" class="form-control" id="fileupload" accept=".dotx"  @change="handleFileUpload"/>
            </div>

            <div v-if="fileSizeErrorMessage != ''" class="alert alert-danger mb-3">
              {{ fileSizeErrorMessage }}
            </div>

            <hr class="my-3">

            <div v-if="errorMsg != ''" class="alert alert-danger mb-3">
              Es ist ein Fehler aufgetreten:<br>
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary me-3" @click="closeModal()">Abbrechen</button>
              <button type="submit" class="btn btn-primary" >Speichern</button>
            </div>
          </form>
        </div>

        <div v-if="editDokument.id != 0" class="modal-footer justify-content-start">
          <button type="button" class="btn btn-danger" @click="deleteDokument()"><font-awesome-icon icon="trash" /> Dokument löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, PropType, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';

import { ActionTypes as DokumenteActionTypes } from "@/store/modules/Dokumente/actions";

import LoadingSpinner from '../LoadingSpinner.vue';

import { Dokument, CreateDokumentRequest } from '../../models/DokumentModels';

export default defineComponent({
  name: "EditDokumentModal",

  components: {
    LoadingSpinner,
  },

  props: {
    dokument: {
      type: Object as PropType<Dokument>,
      default: new Dokument()
    },

    index: {
      type: Number,
      required: true
    },

    buttonStyle: {
      type: Number as PropType<number>,
      default: 0 // 0 = erstellen; 1 = bearbeiten
    }
  },

  emits: ["deleteDokument"],

  setup(props, { emit }) {
    const store = useStore();
    
    const loading = computed(() => store.getters.status.createDokumentLoading || store.getters.status.updateDokumentLoading)
    const errorMsg = computed(() => store.getters.status.dokumenteErrorMsg)

    const editDokument = ref(JSON.parse(JSON.stringify(props.dokument)) as Dokument)

    const editDokumentModalRef = ref()
    function closeModal() {
      Modal.getInstance(editDokumentModalRef.value)?.hide();
    }
    function showModal() {
      if (editDokumentModalRef.value) {
        editDokument.value = JSON.parse(JSON.stringify(props.dokument));
        file.value = null;
        new Modal(editDokumentModalRef.value).show();
      }
    }

    const MAX_FILE_SIZE = 3 * 1024 * 1024
    const file = ref<File | null>(null);
    const fileSizeErrorMessage = ref("");

    function handleFileUpload (event: Event) {
      fileSizeErrorMessage.value = "";

      const inputElement = event.target as HTMLInputElement
      const selectedfile = inputElement.files![0]

      
      if (selectedfile) {
        file.value = selectedfile
      }

      if (file.value != null && file.value.size > MAX_FILE_SIZE) {
        fileSizeErrorMessage.value = "Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 3MB."
      }
    }

    function saveDokument() {
      if (file.value != null && file.value.size > MAX_FILE_SIZE) {
        fileSizeErrorMessage.value = "Die Anlagengröße überschreitet das erlaubte Maximum. Die maximale Größe ist 3MB."
        return
      }

      const requestData: CreateDokumentRequest = {
        id: editDokument.value.id,
        titel: editDokument.value.titel,
        kategorie: editDokument.value.kategorie,
        file: file.value,
        fileName: file.value?.name || ""
      };

      if (editDokument.value.id != 0) {
        store.dispatch(DokumenteActionTypes.UpdateDokument, requestData)
        .then(() => {
          if (errorMsg.value == "") {
            closeModal();
          }
        })
      } else {
        store.dispatch(DokumenteActionTypes.CreateDokument, requestData)
        .then(() => {
          if (errorMsg.value == "") {
            closeModal();
          }
        })
      }
    }

    function deleteDokument() {
      closeModal();
      // emit("deleteDokument", props.dokument.id);
      store.dispatch(DokumenteActionTypes.DeleteDokument, props.dokument.id)
    }

    return {
      loading,
      errorMsg,
      fileSizeErrorMessage,

      editDokument,

      editDokumentModalRef,
      closeModal,
      showModal,

      handleFileUpload,
      saveDokument,
      deleteDokument,
    }
  },
})
</script>

<style scoped>
.modal-content {
  background: rgb(242, 243, 248);
}

/* .modal-body {
  background: rgb(242, 243, 248);
}

.modal-footer {
  background: rgb(242, 243, 248);
} */
</style>