import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard/DashboardView.vue'

import Personaldaten from '@/views/Personaldaten/PersonaldatenView.vue'
import DetailsPersonaldaten from '@/views/Personaldaten/DetailsPersonaldatenView.vue'

import Dokumente from '@/views/Dokumente/DokumenteView.vue'

import Vertraege from '@/views/Vertraege/VertraegeView.vue'
import EditVertrag from "@/views/Vertraege/EditVertragView.vue"
import DetailsVertrag from "@/views/Vertraege/DetailsVertragView.vue"

import Vertragspartner from '@/views/Vertragspartner/VertragspartnerView.vue'
import EditVertragspartner from "@/views/Vertragspartner/EditVertragspartnerView.vue"
import DetailsVertragspartner from "@/views/Vertragspartner/DetailsVertragspartnerView.vue"

import Vorlagen from '@/views/Vorlagen/VorlagenView.vue'
import EditVorlage from '@/views/Vorlagen/EditVorlageView.vue'
import DetailsVorlage from '@/views/Vorlagen/DetailsVorlageView.vue'

import Textbausteine from '@/views/Vorlagen/TextbausteineView.vue'
import EditTextbaustein from '@/views/Vorlagen/EditTextbausteinView.vue'

import Textvariablen from '@/views/Textvariablen/TextvariablenView.vue'

const routes: Array<RouteRecordRaw> = [

  //#region Dashboard / Home
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },

  {
    path: '/start',
    name: 'Home',
    component: Home
  },
  //#endregion

  //#region Personaldaten
  {
    path: '/mitarbeiter',
    name: 'Personaldaten',
    component: Personaldaten
  },
  {
    path: '/mitarbeiter/details/:guid',
    name: 'DetailsPersonaldaten',
    component: DetailsPersonaldaten,
    props: true
  },
  //#endregion

  //#region Vertraege
  {
    path: '/vertraege',
    name: 'Vertraege',
    component: Vertraege
  },
  {
    path: '/vertrag/edit',
    name: 'EditVertrag',
    component: EditVertrag,
    props: route => ({ vertragid: route.query.vertragid, guid: route.query.guid })
  },
  {
    path: '/vertrag/details',
    name: 'DetailsVertrag',
    component: DetailsVertrag,
    props: route => ({ vertragid: route.query.vertragid, guid: route.query.guid })
  },
  //#endregion

  //#region Vertragspartner
  {
    path: '/vertragspartner',
    name: 'Vertragspartner',
    component: Vertragspartner
  },
  {
    path: '/vertragspartner/edit',
    name: 'EditVertragspartner',
    component: EditVertragspartner,
    props: route => ({ vertragspartnerid: route.query.vertragspartnerid })
  },
  {
    path: '/vertragspartner/details',
    name: 'DetailsVertragspartner',
    component: DetailsVertragspartner,
    props: route => ({ vertragspartnerid: route.query.vertragspartnerid })
  },
  //#endregion

  //#region 
  {
    path: '/dokumente',
    name: 'Dokumente',
    component: Dokumente
  },
  //#endregion

  //#region Vorlagen
  {
    path: '/vorlagen',
    name: 'Vorlagen',
    component: Vorlagen
  },
  {
    path: '/vorlagen/edit',
    name: 'EditVorlage',
    component: EditVorlage,
    props: route => ({ vorlageid: route.query.vorlageid })
  },
  {
    path: '/vorlagen/details/:vorlageid',
    name: 'DetailsVorlage',
    component: DetailsVorlage,
    props: true
  },
  //#endregion

  //#region Textbausteine
  {
    path: '/textbausteine',
    name: 'Textbausteine',
    component: Textbausteine
  },
  {
    path: '/textbaustein/edit',
    name: 'EditTextbaustein',
    component: EditTextbaustein,
    props: route => ({ textbausteinid: route.query.textbausteinid })
  },
  //#endregion

  //#region Textvariablen
  {
    path: '/textvariablen',
    name: 'Textvariablen',
    component: Textvariablen
  },
  //#endregion

  //#region Login & Logout
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  //#endregion

  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/logout', '/start'];
  const authRequired = !publicPages.includes(to.path);
  const store = useStore();
  // const personalLoggedIn = store.getters.status.personalLoggedIn

  // let loggedIn = false;

  // if ((localStorage.getItem('user') || sessionStorage.getItem('user')) && personalLoggedIn) {
  //   loggedIn = true
  // }

  let loggedIn = false;

  if ((localStorage.getItem('authUser') || sessionStorage.getItem('authUser'))) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next();
});

export default router
