import { MutationTree } from "vuex";
import { State } from "./state";

import { Vertrag } from "@/models/VertragModels";

export enum MutationTypes {
  SetVertraege = "SETVERTRAEGE",
  AddVertrag = "ADDVERTRAG",
  AddOrUpdateVertrag = "UPDATEVERTRAG",
  DeleteVertrag = "DELETEVERTRAG",
}

export type Mutations = {
  [MutationTypes.SetVertraege](state: State, data: Array<Vertrag>): void
  [MutationTypes.AddVertrag](state: State, data: Vertrag): void
  [MutationTypes.AddOrUpdateVertrag](state: State, data: Vertrag): void
  [MutationTypes.DeleteVertrag](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetVertraege](state, data) {
    state.vertraege = data;
    localStorage.setItem("vertraege", JSON.stringify(state.vertraege));
  },
  
  [MutationTypes.AddVertrag](state, data) {
    state.vertraege.push(data)
    localStorage.setItem("vertraege", JSON.stringify(state.vertraege));
  },
  
  [MutationTypes.AddOrUpdateVertrag](state, data) {
    var index = state.vertraege.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.vertraege.splice(index, 1, data)
    }
    else {
      state.vertraege.push(data)
    }
    
    localStorage.setItem("vertraege", JSON.stringify(state.vertraege));
  },
  
  [MutationTypes.DeleteVertrag](state, data) {
    var index = state.vertraege.findIndex(p => p.id == data)
    if (index != -1) {
      state.vertraege.splice(index, 1)
    }
    
    localStorage.setItem("vertraege", JSON.stringify(state.vertraege));
  },

}