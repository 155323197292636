

export function useNumberConverter() {

  const romanNumerals: { [key: number]: string } = {
    1000: 'M',
    900: 'CM',
    500: 'D',
    400: 'CD',
    100: 'C',
    90: 'XC',
    50: 'L',
    40: 'XL',
    10: 'X',
    9: 'IX',
    5: 'V',
    4: 'IV',
    1: 'I'
  };

  function numberToRomanConverter(num: number): string {
    let result = '';

    // Iterate through the Roman numeral mappings
    for (const value in romanNumerals) {
      while (num >= parseInt(value)) {
        result += romanNumerals[value];
        num -= parseInt(value);
      }
    }

    return result;
  }

  function numberToLetterConverter(num: number): string {
    let result = '';
  
    while (num > 0) {
      const remainder = (num - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      num = Math.floor((num - 1) / 26);
    }
  
    return result;
  }

  return {
    numberToRomanConverter,
    numberToLetterConverter
  };  
}