<template>
  <div class="bg-white">
    <div ref="editorRef"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default defineComponent({

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  setup() {
    const editor = ref();

    const editorRef = ref();

    const toolbarOptions = [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      // [{ 'font': [] }], // Add font dropdown here
      // [{ 'size': []}],
      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // [{ 'font': [] }],
      // ['blockquote'],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // ['image'],

      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction


      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];

    return {
      editor,
      editorRef,
      toolbarOptions
    }
  },

  methods: {
    update: function update() {
      this.$emit(
        "update:modelValue",
        // this.editor.getText() ? this.editor.root.innerHTML : ""
        this.editor.getText() ? this.editor.getSemanticHTML() : ""
      );
    },
  },

  mounted() {
    var _this = this;

    this.editor = new Quill(this.$refs.editorRef as HTMLDivElement, {
      modules: {
        toolbar: this.toolbarOptions
      },
      theme: "snow",
      placeholder: "",
    });
    this.editor.root.innerHTML = this.modelValue;
    this.editor.on("text-change", function () {
      return _this.update();
    });
  }
})
</script>


<style>
.ql-container .ql-editor {
  min-height: 300px !important;
}

</style>