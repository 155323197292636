export class Status {
  errorMsg: string;
  loading: boolean;

  //#region Auth
  authErrorMsg: string;
  authLoading: boolean;
  //#endregion

  //#region Personaldaten
  getPersonaldatenLoading: boolean;
  personaldatenErrorMsg: string;
  //#endregion

  //#region Mandanten
  getMandantenLoading: boolean;
  mandantenErrorMsg: string;
  //#endregion

  //#region Dokumente
  getDokumenteLoading: boolean;
  createDokumentLoading: boolean;
  updateDokumentLoading: boolean;
  deleteDokumentLoading: boolean;
  dokumenteErrorMsg: string;
  //#endregion

  //#region Variablen
  getVariablenLoading: boolean;
  createVariablenLoading: boolean;
  updateVariablenLoading: boolean;
  deleteVariablenLoading: boolean;
  variablenErrorMsg: string;
  //#endregion

  //#region Vorlagen
  getVorlageLoading: boolean;
  createVorlageLoading: boolean;
  updateVorlageLoading: boolean;
  deleteVorlageLoading: boolean;
  vorlageErrorMsg: string;
  //#endregion

  //#region Textbausteine
  getTextbausteineLoading: boolean;
  createTextbausteinLoading: boolean;
  updateTextbausteinLoading: boolean;
  deleteTextbausteinLoading: boolean;
  textbausteinErrorMsg: string;
  //#endregion

  //#region Vertrag
  getVertraegeLoading: boolean;
  createVertragLoading: boolean;
  updateVertragLoading: boolean;
  deleteVertragLoading: boolean;
  vertragErrorMsg: string;
  //#endregion

  //#region Vertragspartner
  getVertragspartnerLoading: boolean;
  createVertragspartnerLoading: boolean;
  updateVertragspartnerLoading: boolean;
  deleteVertragspartnerLoading: boolean;
  vertragspartnerErrorMsg: string;
  //#endregion

  //#region Vertrag Abschnitt
  getAbschnitteLoading: boolean;
  createAbschnittLoading: boolean;
  updateAbschnittLoading: boolean;
  deleteAbschnittLoading: boolean;
  abschnittErrorMsg: string;
  //#endregion

  sidebarCollapsed: boolean;

  constructor () {
    this.errorMsg = "";
    this.loading = false;

    //#region Auth
    this.authLoading = false;
    this.authErrorMsg = "";
    //#endregion

    //#region Personaldaten
    this.getPersonaldatenLoading = false;
    this.personaldatenErrorMsg = "";
    //#endregion

    //#region Mandanten
    this.getMandantenLoading = false;
    this.mandantenErrorMsg = "";
    //#endregion

    //#region Dokumente
    this.getDokumenteLoading = false;
    this.createDokumentLoading = false;
    this.updateDokumentLoading = false;
    this.deleteDokumentLoading = false;
    this.dokumenteErrorMsg = "";
    //#endregion

    //#region Variablen
    this.getVariablenLoading = false;
    this.createVariablenLoading = false;
    this.updateVariablenLoading = false;
    this.deleteVariablenLoading = false;
    this.variablenErrorMsg = "";
    //#endregion

    //#region Vorlage
    this.getVorlageLoading = false;
    this.createVorlageLoading = false;
    this.updateVorlageLoading = false;
    this.deleteVorlageLoading = false;
    this.vorlageErrorMsg = "";
    //#endregion

    //#region Textbaustein
    this.getTextbausteineLoading = false;
    this.createTextbausteinLoading = false;
    this.updateTextbausteinLoading = false;
    this.deleteTextbausteinLoading = false;
    this.textbausteinErrorMsg = "";
    //#endregion

    //#region Vertraege
    this.getVertraegeLoading = false;
    this.createVertragLoading = false;
    this.updateVertragLoading = false;
    this.deleteVertragLoading = false;
    this.vertragErrorMsg = "";
    //#endregion

    //#region Vertragspartner
    this.getVertragspartnerLoading = false;
    this.createVertragspartnerLoading = false;
    this.updateVertragspartnerLoading = false;
    this.deleteVertragspartnerLoading = false;
    this.vertragspartnerErrorMsg = "";
    //#endregion

    //#region Vertrag Abschnitt
    this.getAbschnitteLoading = false;
    this.createAbschnittLoading = false;
    this.updateAbschnittLoading = false;
    this.deleteAbschnittLoading = false;
    this.abschnittErrorMsg = "";
    //#endregion

    this.sidebarCollapsed = false;
  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}