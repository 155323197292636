<template>
  <h1 v-if="vertrag != null">{{vertrag.titel}} bearbeiten</h1>
  <h1 v-else >Neuer Vertrag</h1>

  <!-- SELECT VERTRAGSPARTEIEN -->
  <section v-if="displaySection == 0" class="mt-4">
    <h2>Vertragslaufzeit</h2>

    <div class="py-2 border shadow">
      <div class="row mx-1">
        <div class="col">
          <label for="vertrag-beginn" class="form-label">Vertragsbeginn</label>
          <input type="date" class="form-control" id="vertrag-beginn" v-model="startDatumConverter">
        </div>

        <div class="col">
          <label for="vertrag-ende" class="form-label">Vertragsende</label>
          <input type="date" class="form-control" id="vertrag-ende" v-model="endeDatumConverter">
        </div>
      </div>

      <hr class="my-3">

      <div class="row mx-1">
        <div class="col-6">
          <label for="vertrag-delete" class="form-label">Löschdatum</label>
          <input type="date" class="form-control" id="vertrag-delete" v-model="deleteDatumConverter">
        </div>
      </div>
    </div>


    <hr class="my-4">

    <h2>Bitte wählen Sie die Vertragsparteien aus</h2>

    <div class="row mx-1">
      <div class="col me-3 p-3 border shadow">
        <h3>Vertragspartner 1</h3>

        <div>
          <p><strong>Vertragspartner auswählen:</strong></p>
          
          <div class="row">
            <div class="col-6">
              <label for="partner-1-select" class="form-label">Vertragspartner</label>
              <select id="partner-1-select" class="form-select" aria-label="Vartragspartner auswählen" v-model="selectedPartner1" @change="setVertragVertragspartner1()">
                <option selected :value="null">Vertragspartner auswählen</option>
                <option v-for="partner in vertragspartner" :key="partner.id" :value="partner"><span v-if="partner.unternehmen != ''">{{ partner.unternehmen }} |</span><span v-if="partner.vorname != '' || partner.name != ''"> {{ partner.vorname }} {{ partner.name }}</span></option>
              </select>
            </div>
          </div>
        </div>

        <hr class="my-4">

        <div class="mb-3">
          <label for="partei-1-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-1-unternehmen" v-model="editVertrag.vertragspartei1.unternehmen">
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-1-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-1-vorname" v-model="editVertrag.vertragspartei1.vorname">
          </div>

          <div class="col">
            <label for="partei-1-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-1-name" v-model="editVertrag.vertragspartei1.name">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-1-strasse" v-model="editVertrag.vertragspartei1.strasse">
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-1-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-1-plz" v-model="editVertrag.vertragspartei1.plz">
          </div>

          <div class="col">
            <label for="partei-1-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-1-ort" v-model="editVertrag.vertragspartei1.ort">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-1-land" v-model="editVertrag.vertragspartei1.land">
        </div>

      </div>
      
      <div class="col ms-3 p-3 border shadow">
        <h3>Vertragspartner 2</h3>

        <div>
          <p><strong>Vertragspartner oder Mitarbeiter auswählen:</strong></p>
          
          <div class="row">
            <div class="col">
              <label for="partner-2-select" class="form-label">Vertragspartner</label>
              <select id="partner-2-select" class="form-select" aria-label="Vartragspartner auswählen" v-model="selectedPartner2" @change="setVertragVertragspartner2()">
                <option selected :value="null">Vertragspartner auswählen</option>
                <option v-for="partner in vertragspartner" :key="partner.id" :value="partner"><span v-if="partner.unternehmen != ''">{{ partner.unternehmen }} |</span><span v-if="partner.vorname != '' || partner.name != ''"> {{ partner.vorname }} {{ partner.name }}</span></option>
              </select>
            </div>
            
            <div class="col">
              <label for="mitarbeiter-2-select" class="form-label">Mitarbeiter</label>
              <select id="mitarbeiter-2-select" class="form-select" aria-label="Mitarbeiter auswählen" v-model="selectedMitarbeiter2" @change="setVertragMitarbeiter2()">
                <option selected :value="null">Mitarbeiter auswählen</option>
                <option v-for="pers in mitarbeiter" :key="pers.id" :value="pers">{{ pers.guid }} | {{ pers.vorname }} {{ pers.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <hr class="my-4">

        <div class="mb-3">
          <label for="partei-2-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-2-unternehmen" v-model="editVertrag.vertragspartei2.unternehmen">
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-2-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-2-vorname" v-model="editVertrag.vertragspartei2.vorname">
          </div>

          <div class="col">
            <label for="partei-2-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-2-name" v-model="editVertrag.vertragspartei2.name">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-2-strasse" v-model="editVertrag.vertragspartei2.strasse">
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-2-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-2-plz" v-model="editVertrag.vertragspartei2.plz">
          </div>

          <div class="col">
            <label for="partei-2-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-2-ort" v-model="editVertrag.vertragspartei2.ort">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-2-land" v-model="editVertrag.vertragspartei2.land">
        </div>

      </div>
    </div>

    <div class="d-flex justify-content-center mt-5">
      <button class="btn btn-secondary" @click="nextSection()">Weiter</button>
    </div>
  </section>

  <!-- SELECT VORLAGE -->
  <section v-if="displaySection == 1" class="mt-4">
    <h2>Bitte wählen Sie eine Vorlage aus</h2>

    <div class="row mx-1 mb-2">
      <div class="col-8 fw-bold my-auto">Titel</div>
      <div class="col-4 fw-bold my-auto">Abschnitte</div>
    </div>

    <div v-for="vorlage in vorlagen" :key="vorlage.id" class="row mx-1 mb-3 py-3 shadow-sm vorlageRow" :class="{ 'selectedVorlage': vorlage.id == selectedVorlage?.id}" @click="selectVorlage(vorlage.id)">
      <div class="col-8">
        <strong>{{ vorlage.name }}</strong> ({{vorlage.titel}})
      </div>

      <div class="col-4">{{ vorlage.vorlageTextbausteine.length }}</div>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-secondary me-3" @click="prevSection()" >Zurück</button>

      <button class="btn btn-secondary" @click="nextSection()" :disabled="selectedVorlage == null">Weiter</button>
    </div>
  </section>

  <!-- SELECT ABSCHNITTE -->
  <section v-else-if="displaySection == 2 && selectedVorlage != null" class="mt-4">
    <h2>Bitte wählen Sie die Vertragsabschnitte aus</h2>

    <div v-for="baustein in selectedVorlage.vorlageTextbausteine.sort((a, b) => a.position - b.position)" class="mb-2">
      <div class="form-check d-flex align-items-center">
        <input class="form-check-input" type="checkbox" :value="baustein.textbaustein" :id="'abschnitt-' + baustein.id" v-model="selectedAbschnitte" @change="setBausteinSelected(baustein)">
        <label class="form-check-label flex-fill ms-2 p-2 shadow-sm abschnittRow" :class="{ 'selectedAbschnitt': selectedAbschnitte.includes(baustein.textbaustein)}" :for="'abschnitt-' + baustein.id">
          <strong>{{ baustein.textbaustein.titel }}</strong> ({{ baustein.textbaustein.name }})
        </label>
      </div>

      <div v-for="abschnitt in baustein.textbaustein.abschnitte" :key="abschnitt.id" class="ms-5 d-flex align-items-center ">
        <input class="form-check-input" type="checkbox" :value="abschnitt" :id="'abschnitt-' + abschnitt.id" v-model="selectedAbschnitte">
        <label class="form-check-label flex-fill ms-2 p-2 shadow-sm abschnittRow" :class="{ 'selectedAbschnitt': selectedAbschnitte.includes(abschnitt)}" :for="'abschnitt-' + abschnitt.id" @click.prevent="setAbschnittSelected(abschnitt)">
          <strong>{{ abschnitt.titel }}</strong> ({{ abschnitt.name }})
        </label>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button class="btn btn-secondary me-3" @click="prevSection()" >Zurück</button>
      <button class="btn btn-secondary" @click="nextSection()" :disabled="selectedAbschnitte.length == 0">Weiter</button>
    </div>
  </section>

  <!-- VARIABLEN INPUT -->
  <section v-else-if="displaySection == 3 && selectedAbschnitte.length > 0" class="mt-4">
    <div v-for="(abschnitt, index) in selectedAbschnitte.filter(a => a.ownerid == null || a.ownerid == 0).sort((a, b) => a.position - b.position)" :key="abschnitt.id" class="bg-white shadow p-3 mb-3">
      <AbschnittInput  :abschnitt="abschnitt" :personaldaten="personaldaten" :vertrag="editVertrag" :index="index" @get-input-data="getAbschnittInputData" :ref="'abschnittRef-' + abschnitt.id"/>
      
      <div class="ms-4">
        <AbschnittInput v-for="(subabschnitt, idx) in selectedAbschnitte.filter(sa => sa.ownerid == abschnitt.id).sort((a, b) => a.position - b.position)"  :abschnitt="subabschnitt" :personaldaten="personaldaten" :vertrag="editVertrag" :index="index + idx" @get-input-data="getAbschnittInputData" :ref="'abschnittRef-' + subabschnitt.id"/>
      </div>
    </div>
  
    <div class="d-flex mt-4 justify-content-end">
      <button class="btn btn-secondary me-3" @click="prevSection()" >Zurück</button>
      <button class="btn btn-primary" @click="createVertragData()">Weiter</button>
    </div>
  </section>

  <!-- VERTRAG PREVIEW -->
  <section v-else-if="displaySection == 4 && editVertrag.titel != ''">
    <!-- {{ editVertrag }} -->

    <h2>Vertragslaufzeit</h2>

    <div class="py-2 border shadow">
      <div class="row mx-1">
        <div class="col">
          <label for="vertrag-beginn" class="form-label">Vertragsbeginn</label>
          <input type="date" class="form-control" id="vertrag-beginn" v-model="startDatumConverter">
        </div>

        <div class="col">
          <label for="vertrag-ende" class="form-label">Vertragsende</label>
          <input type="date" class="form-control" id="vertrag-ende" v-model="endeDatumConverter">
        </div>
      </div>

      <hr class="my-3">

      <div class="row mx-1">
        <div class="col-6">
          <label for="vertrag-delete" class="form-label">Löschdatum</label>
          <input type="date" class="form-control" id="vertrag-delete" v-model="deleteDatumConverter">
        </div>
      </div>
    </div>


    <hr class="my-4">

    <h2>Vertragsparteien</h2>
    <div class="row mx-1">
      <div class="col me-3 p-3 border shadow">
        <h3>Vertragspartner 1</h3>

        <div class="mb-3">
          <label for="partei-1-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-1-unternehmen" v-model="editVertrag.vertragspartei1.unternehmen">
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-1-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-1-vorname" v-model="editVertrag.vertragspartei1.vorname">
          </div>

          <div class="col">
            <label for="partei-1-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-1-name" v-model="editVertrag.vertragspartei1.name">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-1-strasse" v-model="editVertrag.vertragspartei1.strasse">
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-1-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-1-plz" v-model="editVertrag.vertragspartei1.plz">
          </div>

          <div class="col">
            <label for="partei-1-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-1-ort" v-model="editVertrag.vertragspartei1.ort">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-1-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-1-land" v-model="editVertrag.vertragspartei1.land">
        </div>

      </div>
      
      <div class="col ms-3 p-3 border shadow">
        <h3>Vertragspartner 2</h3>

        <div class="mb-3">
          <label for="partei-2-unternehmen" class="form-label">Unternhemen</label>
          <input type="text" class="form-control" id="partei-2-unternehmen" v-model="editVertrag.vertragspartei2.unternehmen">
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="partei-2-vorname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="partei-2-vorname" v-model="editVertrag.vertragspartei2.vorname">
          </div>

          <div class="col">
            <label for="partei-2-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="partei-2-name" v-model="editVertrag.vertragspartei2.name">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-strasse" class="form-label">Straße & Hausnummer</label>
          <input type="text" class="form-control" id="partei-2-strasse" v-model="editVertrag.vertragspartei2.strasse">
        </div>

        <div class="row mb-3">
          <div class="col-4">
            <label for="partei-2-plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="partei-2-plz" v-model="editVertrag.vertragspartei2.plz">
          </div>

          <div class="col">
            <label for="partei-2-ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="partei-2-ort" v-model="editVertrag.vertragspartei2.ort">
          </div>
        </div>

        <div class="mb-3">
          <label for="partei-2-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="partei-2-land" v-model="editVertrag.vertragspartei2.land">
        </div>

      </div>
    </div>
    
    <hr class="my-4">

    <h2>Abschnitte</h2>
    <div v-for="(abschnitt, index) in editVertrag.textabschnitte.filter(a => a.ownerid == null).sort((a, b) => a.position - b.position)" :key="index" class="row mx-1 p-3 mb-4">
      <div v-if="editVertrag.textabschnitte.length > 1" class="col-1 d-flex flex-column mt-3">
        <button type="button" v-if="abschnitt.position != 1" class="btn p-0" @click="abschnittMoveUp(index)"><font-awesome-icon icon="chevron-up" class="fa-sm" /></button>
        <button type="button" v-if="abschnitt.position != editVertrag.textabschnitte.filter(a => a.ownerid == null).length" class="btn p-0" @click="abschnittMoveDown(index)"><font-awesome-icon icon="chevron-down" class="fa-sm" /></button>
      </div>

      <div class="col bg-white shadow-sm">
        <div class="d-flex justify-content-between">
          <h3>{{ abschnitt.position }} {{ abschnitt.titel }}</h3>

          <button type="button" class="btn" @click="removeAbschnitt(index, null)"><font-awesome-icon icon="trash" /></button>
        </div>
        <strong>Nummerierung:</strong> <span v-if="abschnitt.nummerierungSetting">Ja</span><span v-else>Nein</span>

        <div class="row my-3">
          <div class="col-3">
            <div v-for="input in abschnitt.inputs" :key="input.id" class="mb-2">
              <!-- <strong>{{ input.key }}</strong><br><span>{{ input.value }}</span>
              <div v-for="input of abschnitt.inputs" :key="input.key" class="mb-2 border-bottom p-2 bg-white shadow-sm"> -->
                <div class="mb-2">
                  <label :for="'variable-edit-' + input.key" class="form-label">{{input.key}}</label>
                  <input type="text" class="form-control" :id="'variable-edit-' + input.key" v-model="input.value">
                </div>
              <!-- </div> -->
            </div>
          </div>

          <div class="col-9">
            <TextEditor v-model="abschnitt.text" />
          </div>
        </div>

        <hr v-if="abschnitt.abschnitte.length > 0" class="my-4">

        <div v-for="(subabschnitt, idx) in abschnitt.abschnitte" :key="idx" class="ms-5 mb-3">
          <div class="d-flex justify-content-between">
            <h4>{{ subabschnitt.titel }}</h4>

            <button type="button" class="btn" @click="removeAbschnitt(index, idx)"><font-awesome-icon icon="trash" /></button>
          </div>
          <strong>Nummerierung:</strong> <span v-if="subabschnitt.nummerierungSetting">Ja</span><span v-else>Nein</span>

          <div class="row mt-3">
            <div class="col-3">
              <div v-for="input in subabschnitt.inputs" :key="input.id" class="mb-2">
                <!-- <strong>{{ input.key }}</strong><br><span>{{ input.value }}</span> -->
                <div class="mb-2">
                  <label :for="'variable-edit-' + input.key" class="form-label">{{input.key}}</label>
                  <input type="text" class="form-control" :id="'variable-edit-' + input.key" v-model="input.value">
                </div>
              </div>
            </div>
            
            <div class="col-9">
              <TextEditor v-model="subabschnitt.text" />
            </div>
          </div>

          <hr v-if="idx != abschnitt.abschnitte.length - 1">
        </div>
      </div>
    </div>

    <LoadingSpinner v-if="createVertragLoading" text="... Vertrag wird erstellt ..." />

    <div v-else-if="!createVertragLoading && vertragErrorMsg != ''" class="alert alert-danger my-3">Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut</div>

    <div v-if="!createVertragLoading" class="d-flex mt-4 justify-content-end">
      <button v-if="vertrag == null" class="btn btn-secondary me-3" @click="prevSection()" >Zurück</button>
      <button class="btn btn-primary" @click="saveVertrag()">Speichern</button>
    </div>
  </section>

  <hr class="my-4">

  <button class="btn btn-secondary float-end" @click="cancel()">Abbrechen</button>

  <!-- <pre>{{ editVertrag }}</pre> -->
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import { ActionTypes as VertragActionTypes } from "@/store/modules/Vertraege/actions";
import { ActionTypes as VorlagenActionTypes } from "@/store/modules/Vorlagen/actions";
import { ActionTypes as VariablenActionTypes } from "@/store/modules/Variablen/actions";
import { ActionTypes as VertragspartnerActionTypes } from "@/store/modules/Vertragspartner/actions";

import { Personaldaten } from "@/models/PersonaldatenModels"
import { TextAbschnitt, Vertrag } from "@/models/VertragModels";
import { NummerierungEinstellung, Textbaustein, Vorlage, VorlageTextbaustein } from "@/models/VorlageModels";
import { Vertragspartner } from "@/models/VertragspartnerModels";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AbschnittInput from "@/components/Vertrag/AbschnittInput.vue"
import TextEditor from "@/components/TextEditor/TextEditor.vue";

export default defineComponent({
  name: "EditVertrag",

  components: {
    LoadingSpinner,
    AbschnittInput,
    TextEditor
  },

  props: {
    vertragid: {
      type: String as PropType<string>,
      default: ""
    },

    guid: {
      type: String as PropType<string>,
      default: ""
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const displaySection = ref(0)

    function nextSection() {
      displaySection.value++;
    }

    function prevSection() {
      if (displaySection.value > 0) {
        displaySection.value--;
      }
    }

    const vertrag = computed(() => store.getters.vertraege.find((v: Vertrag) => v.id == parseInt(props.vertragid)))
    

    const editVertrag = ref(vertrag.value != null ? vertrag.value : new Vertrag())
    if (vertrag.value != null && vertrag.value.id != 0) {
      displaySection.value = 4

      if (editVertrag.value.vertragspartei1 == null) {
        editVertrag.value.vertragspartei1 = new Vertragspartner()
      }
      if (editVertrag.value.vertragspartei2 == null) {
        editVertrag.value.vertragspartei2 = new Vertragspartner()
      }
    }

    function abschnittMoveUp(index: number) {
      if (index > 0) {
        var abschnitte = editVertrag.value.textabschnitte.filter((a: TextAbschnitt) => a.ownerid == null).sort((a, b) => a.position - b.position)
        abschnitte[index - 1].position += 1
        abschnitte[index].position -= 1
      }
    }

    function abschnittMoveDown(index: number) {
      var abschnitte = editVertrag.value.textabschnitte.filter((a: TextAbschnitt) => a.ownerid == null).sort((a, b) => a.position - b.position)
      if (index < abschnitte.length - 1) {
        abschnitte[index + 1].position -= 1
        abschnitte[index].position += 1
      }
    }

    function removeAbschnitt(index: number, subindex: number | null = null) {
      if (subindex != null) {
        editVertrag.value.textabschnitte[index].abschnitte.splice(subindex, 1)
      }
      else {
        editVertrag.value.textabschnitte.splice(index, 1)
      }
    }

    //#region Vertragslaufzeit
    const startDatumConverter = computed({
      get() {
        if (editVertrag.value.vertragsBeginn != null) {
          const date = new Date(editVertrag.value.vertragsBeginn)
          return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        }
        else {
          return null
        }
      },

      set(value) {
        if (value != null && value != "") {
          editVertrag.value.vertragsBeginn = new Date(value)
        }
        else {
          editVertrag.value.vertragsBeginn = null
        }
      }
    })

    const endeDatumConverter = computed({
      get() {
        if (editVertrag.value.vertragsEnde != null) {
          const date = new Date(editVertrag.value.vertragsEnde)
          return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        }
        else {
          return null
        }
      },

      set(value) {
        if (value != null && value != "") {
          editVertrag.value.vertragsEnde = new Date(value)
        }
        else {
          editVertrag.value.vertragsEnde = null
        }
      }
    })

    const deleteDatumConverter = computed({
      get() {
        if (editVertrag.value.ddate != null) {
          const date = new Date(editVertrag.value.ddate)
          return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        }
        else {
          return null
        }
      },

      set(value) {
        if (value != null && value != "") {
          editVertrag.value.ddate = new Date(value)
        }
        else {
          editVertrag.value.ddate = null
        }
      }
    })
    //#endregion

    //#region Vertragspartner
    const vertragspartner = computed(() => store.getters.vertragspartner)
    const mitarbeiter = computed(() => store.getters.personaldaten)

    const selectedPartner1 = ref<Vertragspartner | null>(null)
    function setVertragVertragspartner1() {
      if (selectedPartner1.value != null) {
        editVertrag.value.vertragspartei1.name = selectedPartner1.value.name
        editVertrag.value.vertragspartei1.vorname = selectedPartner1.value.vorname
        editVertrag.value.vertragspartei1.unternehmen = selectedPartner1.value.unternehmen
        editVertrag.value.vertragspartei1.strasse = selectedPartner1.value.strasse
        editVertrag.value.vertragspartei1.plz = selectedPartner1.value.plz
        editVertrag.value.vertragspartei1.ort = selectedPartner1.value.ort
        editVertrag.value.vertragspartei1.land = selectedPartner1.value.land
      }
      else {
        editVertrag.value.vertragspartei1.name = "";
        editVertrag.value.vertragspartei1.vorname = "";
        editVertrag.value.vertragspartei1.unternehmen = "";
        editVertrag.value.vertragspartei1.strasse = "";
        editVertrag.value.vertragspartei1.plz = "";
        editVertrag.value.vertragspartei1.ort = "";
        editVertrag.value.vertragspartei1.land = "";
      }
    }

    const selectedPartner2 = ref<Vertragspartner | null>(null)
    function setVertragVertragspartner2() {
      selectedMitarbeiter2.value = null
      if (selectedPartner2.value != null) {
        editVertrag.value.vertragspartei2.name = selectedPartner2.value.name
        editVertrag.value.vertragspartei2.vorname = selectedPartner2.value.vorname
        editVertrag.value.vertragspartei2.unternehmen = selectedPartner2.value.unternehmen
        editVertrag.value.vertragspartei2.strasse = selectedPartner2.value.strasse
        editVertrag.value.vertragspartei2.plz = selectedPartner2.value.plz
        editVertrag.value.vertragspartei2.ort = selectedPartner2.value.ort
        editVertrag.value.vertragspartei2.land = selectedPartner2.value.land

        editVertrag.value.personaldatenGuid = "";
      }
      else {
        editVertrag.value.vertragspartei2.name = "";
        editVertrag.value.vertragspartei2.vorname = "";
        editVertrag.value.vertragspartei2.unternehmen = "";
        editVertrag.value.vertragspartei2.strasse = "";
        editVertrag.value.vertragspartei2.plz = "";
        editVertrag.value.vertragspartei2.ort = "";
        editVertrag.value.vertragspartei2.land = "";

        editVertrag.value.personaldatenGuid = "";
      }
    }

    const selectedMitarbeiter2 = ref<Personaldaten | null>(null)

    if (props.guid != "") {
      const persdata = store.getters.personaldaten.find((p: Personaldaten) => p.guid == props.guid)
      if (persdata != undefined) {
        selectedMitarbeiter2.value = persdata;
        setVertragMitarbeiter2();
      }
    }

    function setVertragMitarbeiter2() {
      selectedPartner2.value = null
      if (selectedMitarbeiter2.value != null) {
        editVertrag.value.vertragspartei2.name = selectedMitarbeiter2.value.name
        editVertrag.value.vertragspartei2.vorname = selectedMitarbeiter2.value.vorname
        editVertrag.value.vertragspartei2.strasse = selectedMitarbeiter2.value.strasse
        editVertrag.value.vertragspartei2.plz = selectedMitarbeiter2.value.plz
        editVertrag.value.vertragspartei2.ort = selectedMitarbeiter2.value.ort
        editVertrag.value.vertragspartei2.land = selectedMitarbeiter2.value.land

        editVertrag.value.personaldatenGuid = selectedMitarbeiter2.value.guid
      }
      else {
        editVertrag.value.vertragspartei2.name = "";
        editVertrag.value.vertragspartei2.vorname = "";
        editVertrag.value.vertragspartei2.unternehmen = "";
        editVertrag.value.vertragspartei2.strasse = "";
        editVertrag.value.vertragspartei2.plz = "";
        editVertrag.value.vertragspartei2.ort = "";
        editVertrag.value.vertragspartei2.land = "";

        editVertrag.value.personaldatenGuid = "";
      }
    }
    //#endregion

    const vorlagen = computed(() => store.getters.vorlagen)
    const selectedVorlage = ref<Vorlage>();
    function selectVorlage(id: number) {
      selectedVorlage.value = JSON.parse(JSON.stringify(vorlagen.value.find((v: Vorlage) => v.id == id))) as Vorlage

      selectedVorlage.value?.vorlageTextbausteine.forEach((abschnitt: VorlageTextbaustein) => {
        if (!abschnitt.optional) {
          selectedAbschnitte.value.push(abschnitt.textbaustein)
          selectedAbschnitte.value.push(...abschnitt.textbaustein.abschnitte)
        }
      })

      nextSection();
    }

    const selectedAbschnitte = ref(new Array<Textbaustein>())
    function setBausteinSelected(baustein: VorlageTextbaustein) {
      const selectedBaustein = selectedAbschnitte.value.find((abschnitt: Textbaustein) => abschnitt.id == baustein.textbausteinid)
      if (selectedBaustein != null) {
        selectedBaustein.position = baustein.position;
        selectedAbschnitte.value.push(...baustein.textbaustein.abschnitte)
      }
      else {
        baustein.textbaustein.abschnitte.forEach(a => {
          const index = selectedAbschnitte.value.findIndex((abschnitt: Textbaustein) => abschnitt.id == a.id)
          if (index > -1) {
            selectedAbschnitte.value.splice(index, 1)
          }
        })
      }
    }

    function setAbschnittSelected(abschnitt: Textbaustein) {
      const index = selectedAbschnitte.value.findIndex((a: Textbaustein) => a.id == abschnitt.id)
      if (index > -1) {
        selectedAbschnitte.value.splice(index, 1)
      }
      else {
        selectedAbschnitte.value.push(abschnitt)

        const parent = selectedVorlage.value?.vorlageTextbausteine.find((v: VorlageTextbaustein) => v.textbausteinid == abschnitt.ownerid)
        if (parent != null && !selectedAbschnitte.value.includes(parent.textbaustein)) {
          parent.textbaustein.position = parent.position;
          selectedAbschnitte.value.push(parent.textbaustein);
        }
      }
    }

    function getAbschnittInputData(data: object) {
      // console.log(data);
    }

    const createVertragLoading = computed(() => store.getters.status.createVertragLoading)
    const vertragErrorMsg = computed(() => store.getters.status.vertragErrorMsg)

    function saveVertrag() {
      if (vertrag.value != null) {
        store.dispatch(VertragActionTypes.UpdateVertrag, editVertrag.value)
        .then(() => {
          if (vertragErrorMsg.value == "") {
            router.push({name: "DetailsVertrag", query: {vertragid: props.vertragid, guid: props.guid}})
          }
        })
      }
      else {
        store.dispatch(VertragActionTypes.CreateVertrag, editVertrag.value)
        .then(() => {
          if (vertragErrorMsg.value == "") {
            if (props.vertragid == "") {
              router.push({name: "Vertraege"})
            }
            else {
              router.push({name: "DetailsVertrag", query: {vertragid: props.vertragid, guid: props.guid}})
            }
          }
        })
      }
    }


    const personaldaten = computed(() => {
      if (selectedMitarbeiter2.value != null) {
        return selectedMitarbeiter2.value
      }
      else {
        return new Personaldaten()
      }
    })


    function cancel() {
      router.back();
    }

    return {
      store,
      displaySection,
      nextSection,
      prevSection,

      vertrag,
      editVertrag,

      abschnittMoveUp,
      abschnittMoveDown,
      removeAbschnitt,

      startDatumConverter,
      endeDatumConverter,
      deleteDatumConverter,

      vertragspartner,
      mitarbeiter,
      selectedPartner1,
      setVertragVertragspartner1,
      selectedPartner2,
      setVertragVertragspartner2,
      selectedMitarbeiter2,
      setVertragMitarbeiter2,

      vorlagen,

      selectedVorlage,
      selectVorlage,
      
      selectedAbschnitte,
      setBausteinSelected,
      setAbschnittSelected,

      getAbschnittInputData,
      createVertragLoading,
      vertragErrorMsg,
      saveVertrag,
      personaldaten,
      cancel
    }
  },

  methods: {
    createVertragData () {
      // this.editVertrag.personaldatenGuid = this.guid;
      
      if (this.selectedVorlage != null) {
        this.editVertrag.titel = this.selectedVorlage.titel

        this.editVertrag.nummerierungEinstellungen = new Array<NummerierungEinstellung>()
        for (let setting of this.selectedVorlage.nummerierungEinstellungen) {
          const newNummerierungSetting = new NummerierungEinstellung(null, this.editVertrag.id, setting.ebene, setting.praefix, setting.suffix, setting.darstellung, setting.gross, setting.punkt, setting.praefixSupEbene)
          this.editVertrag.nummerierungEinstellungen.push(newNummerierungSetting)
        }
      }

      for (let [index, selected] of this.selectedAbschnitte.filter(a => a.ownerid == null || a.ownerid == 0).sort((a, b) => a.position - b.position).entries()) {
        selected.position = index + 1
      }

      if (this.selectedAbschnitte.length > 0) {

        for (let abschnitt of this.selectedAbschnitte.filter(a => a.ownerid == null || a.ownerid == 0)) {
          const subAbschnitte = new Array<TextAbschnitt>()
          const selectedSubAbschnitte = this.selectedAbschnitte.filter(sa => sa.ownerid == abschnitt.id)

          for (let subabschnitt of selectedSubAbschnitte) {
            const abschnittRef = (this.$refs['abschnittRef-' + subabschnitt.id] as Array<any>)[0] as typeof AbschnittInput
            const inputData = abschnittRef.returnInputData()
            subAbschnitte.push(new TextAbschnitt(0, 0, subabschnitt.titel, subabschnitt.text, subabschnitt.position, subabschnitt.nummerierungSetting, 0, new Array<TextAbschnitt>, inputData))
          }

          const abschnittRef = (this.$refs['abschnittRef-' + abschnitt.id] as Array<any>)[0] as typeof AbschnittInput
          const inputData = abschnittRef.returnInputData()

          const neuerAbschnitt = new TextAbschnitt(0, 0, abschnitt.titel, abschnitt.text, abschnitt.position, abschnitt.nummerierungSetting, null, subAbschnitte, inputData)
          this.editVertrag.textabschnitte.push(neuerAbschnitt)
        }
      }

      this.nextSection();
    },
  },

  mounted () {
    this.store.dispatch(VorlagenActionTypes.GetVorlagen, undefined)
    this.store.dispatch(VorlagenActionTypes.GetTextbausteine, undefined)
    this.store.dispatch(VariablenActionTypes.GetVariablen, undefined)
    this.store.dispatch(VertragspartnerActionTypes.GetVertragspartner, undefined)
  }
})
</script>

<style scoped>
.vorlageRow {
  background: white;
  cursor: pointer;
}

.vorlageRow:hover {
  /* background: #ffd1df;
  color: #770025; */
  background: #fff4af;
}

.selectedVorlage {
  /* background: #ffd1df;
  color: #770025; */
  color: #fff4af;
}

.abschnittRow {
  background: white;
  cursor: pointer;
}

.abschnittRow:hover {
  /* background: #ffd1df;
  color: #770025; */
  background: #fff4af;
}

.selectedAbschnitt {
  /* background: #ffd1df;
  color: #770025; */
  background: #fff4af;
}


</style>