import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Variable } from '@/models/VorlageModels';


export enum ActionTypes {
  GetVariablen = "GETVARIABLEN",
  CreateVariable = "CREATEVARIABLE",
  UpdateVariable = "UPDATEVARIABLE",
  DeleteVariable = "DELETEVARIABLE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetVariablen](context: ActionArguments): Promise<void>
  [ActionTypes.CreateVariable](context: ActionArguments, data: Variable): Promise<void>
  [ActionTypes.UpdateVariable](context: ActionArguments, data: Variable): Promise<void>
  [ActionTypes.DeleteVariable](context: ActionArguments, variableid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetVariablen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getVariablenLoading = true;
    status.variablenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetVariablen, new Array<Variable>())

    return ApiService.getAllVariablen()
      .then(res => {
        const data: Array<Variable> = res.data
        commit(MutationTypes.SetVariablen, data)
      })
      .catch(error => {
        status.variablenErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVariablenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateVariable]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createVariablenLoading = true;
    status.variablenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVariable(data)
      .then(res => {
        const data: Variable = res.data
        commit(MutationTypes.AddVariable, data)
      })
      .catch(error => {
        console.log(error);
        status.variablenErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVariablenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateVariable]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateVariablenLoading = true;
    status.variablenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateVariable(data)
      .then(res => {
        const data: Variable = res.data
        commit(MutationTypes.AddOrUpdateVariable, data)
      })
      .catch(error => {
        status.variablenErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateVariablenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteVariable]({commit, dispatch, rootGetters}, variableid) {
    const status = rootGetters.status;
    status.deleteVariablenLoading = true;
    status.variablenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteVariable(variableid)
      .then(res => {
        commit(MutationTypes.DeleteVariable, variableid)
      })
      .catch(error => {
        console.log(error);
        status.variablenErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteVariablenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}