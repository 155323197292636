<template>
  <router-link :to="{name: 'Vorlagen'}" class="btn btn-outline-secondary"><font-awesome-icon icon="chevron-left"/> Zurück</router-link>

  <div v-if="vorlage == null" class="alert alert-danger mt-5">
    Ungültige Vorlage ID: {{ vorlageid }}
  </div>

  <div v-else>
    <!-- TITEL -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1>{{ vorlage.name }}</h1>

      <router-link :to="{name: 'EditVorlage', query: {vorlageid: vorlageid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <!-- BESCHREIBUNG -->
    <div class="w-50 mb-4">
      <div class="row mb-2">
        <div class="col-3 fw-bold">Vertrag Titel:</div>
        <div class="col">{{ vorlage.titel }}</div>
      </div>

      <div v-if="false">
        <div class="row mb-2">
          <div class="col-3 fw-bold">Nr. Ebene 1:</div>
          <div class="col">{{ nummerierung1example }}</div>
        </div>

        <div class="row mb-2">
          <div class="col-3 fw-bold">Nr. Ebene 2:</div>
          <div class="col">{{ nummerierung2example }}</div>
        </div>
      </div>
    </div>


    <!-- TEXTBAUSTEINE -->
    <p><strong>Abschnitte</strong></p>
    <div v-if="vorlage?.vorlageTextbausteine.length > 0" class="mt-3">
      <TextbausteinRow v-for="vorlageTextbaustein in vorlage.vorlageTextbausteine.sort((a: VorlageTextbaustein, b: VorlageTextbaustein) => a.position - b.position)" :key="vorlageTextbaustein.id" :textbaustein="vorlageTextbaustein.textbaustein" :showEdit="false" class="mx-1 mb-2 p-2 bg-white shadow" />
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';
import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TextbausteinRow from '@/components/Vorlagen/TextbausteinRow.vue';

import { NummerierungEinstellung, Vorlage, VorlageTextbaustein } from '@/models/VorlageModels';

export default defineComponent({
  name: "Kommission",

  components: {
    LoadingSpinner,
    TextbausteinRow
  },

  props: {
    vorlageid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const vorlage = computed(() => store.getters.vorlagen.find((v: Vorlage) => v.id == parseInt(props.vorlageid)))

    const nummerierungEbene1 = ref(vorlage.value?.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == 1))
    const nummerierungEbene2 = ref(vorlage.value?.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == 2))


    const nummerierung1example = computed(() => {
      let nummerierung = "";

      if (nummerierungEbene1.value == null) {
        return nummerierung
      }

      if (nummerierungEbene1.value.praefix != "") {
        nummerierung += nummerierungEbene1.value.praefix + " "
      }

      if (nummerierungEbene1.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene1.value.darstellung == 2) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene1.value.darstellung == 3) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene1.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene1.value.suffix != "") {
        nummerierung += " " + nummerierungEbene1.value.suffix
      }

      return nummerierung
    })

    const nummerierung2example = computed(() => {
      let nummerierung = "";

      if (nummerierungEbene1.value == null || nummerierungEbene2.value == null) {
        return nummerierung
      }

      if (nummerierungEbene2.value.praefix != "") {
        nummerierung += nummerierungEbene2.value.praefix + " "
      }

      if (nummerierungEbene2.value.praefixSupEbene) {
        if (nummerierungEbene1.value.darstellung == 1) {
          nummerierung += "1."
        } else if (nummerierungEbene1.value.darstellung == 2) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "I."
          } else {
            nummerierung += "i."
          }
        } else if (nummerierungEbene1.value.darstellung == 3) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "A."
          } else {
            nummerierung += "a."
          }
        }
      }

      if (nummerierungEbene2.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene2.value.darstellung == 2) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene2.value.darstellung == 3) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene2.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene2.value.suffix != "") {
        nummerierung += " " + nummerierungEbene2.value.suffix
      }

      return nummerierung
    })


    return {
      store,
      router,

      vorlage,
      nummerierung1example,
      nummerierung2example
    }
  },

  mounted () {
    if (this.store.getters.textbausteine.length == 0) {
      this.store.dispatch(VorlageActionTypes.GetTextbausteine, undefined)
    }
  }
})
</script>