import { NummerierungEinstellung } from './VorlageModels';

export class Vertrag {
  id: number;
  titel: string;

  vertragsBeginn: Date | null;
  vertragsEnde: Date | null;

  textabschnitte: Array<TextAbschnitt>;
  
  personaldatenGuid: string
  
  nummerierungEinstellungen: Array<NummerierungEinstellung>;

  vertragspartei1: Vertragspartei;
  vertragspartei2: Vertragspartei;

  adate: Date;
  mdate: Date;
  ddate: Date | null;
  edate: Date | null;
  vdate: Date | null;
  archivBenutzerId: number;

  constructor () {
    this.id = 0;
    this.titel = "";
    this.textabschnitte = new Array<TextAbschnitt>();
    this.personaldatenGuid = "";

    this.vertragsBeginn = new Date;
    this.vertragsEnde = null;

    this.nummerierungEinstellungen = [
      new NummerierungEinstellung(null, this.id, 1, "", "", 1, true, true, false),
      new NummerierungEinstellung(null, this.id, 2, "", "", 1, true, false, true),
    ];

    this.vertragspartei1 = new Vertragspartei();
    this.vertragspartei2 = new Vertragspartei();

    this.adate = new Date;
    this.mdate = new Date;
    this.ddate = null;
    this.edate = null;
    this.vdate = null;
    this.archivBenutzerId = 0
  }
}

export class Vertragspartei {
  id: number;
  name: string;
  vorname: string;
  unternehmen: string;
  strasse: string;
  plz: string;
  ort: string;
  land: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.vorname = '';
    this.unternehmen = '';
    this.strasse = '';
    this.plz = '';
    this.ort = '';
    this.land = '';
  }
}

export class Input {
  id: number;
  textabschnittid: number;
  key: string;
  value: string;

  constructor(textabschnittid: number, key: string, value: string) {
    this.id = 0;
    this.textabschnittid = textabschnittid;
    this.key = key;
    this.value = value;
  }
}

export class TextAbschnitt {
  id: number;
  vertragid: number;
  titel: string;
  text: string;
  position: number;
  nummerierungSetting: boolean;
  inputs: Array<Input>
  ownerid: number | null;
  abschnitte: Array<TextAbschnitt>;
  adate: Date;
  mdate: Date;
  archivBenutzerId: number;

  constructor (id: number, vertragid: number, titel: string, text: string, position: number, nummerierungEinstellung: boolean, ownerid: number | null, abschnitte: Array<TextAbschnitt>, inputs: Array<Input>) {
    this.id = id;
    this.vertragid = vertragid;
    this.titel = titel;
    this.text = text;
    this.position = position;
    this.nummerierungSetting = nummerierungEinstellung;
    this.inputs = inputs;
    this.ownerid = ownerid;
    this.abschnitte = abschnitte;
    this.adate = new Date;
    this.mdate = new Date;
    this.archivBenutzerId = 0
  }
}

export class GenerateWordDocumentRequest {
  text: string;
  dokumentId: number | null;
  fileName: string;

  constructor(text: string, dokumentId: number | null = null, fileName: string = "dokument.docx") {
    this.text = text;
    this.dokumentId = dokumentId;
    this.fileName = fileName;
  }
}