<template>
  <!-- <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start"> -->
  <nav id="sidebar" class="shadow d-flex flex-column flex-shrink-0" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">

    <!-- <img v-if="sidebarCollapsed" src="@/assets/icons/ew.png" class="mx-auto mt-3" width="30" @click="toggleSidebar()"> -->
    
    <router-link
      v-if="!sidebarCollapsed"
      :to="{ name: 'Dashboard' }"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-decoration-none
        text-dark
      "
    >
      <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
      <img src="@/assets/icons/personalakte.png" class="me-3" width="40">
      <span class="fs-4">Vertragsmanger</span>
    </router-link>

    <hr v-if="!sidebarCollapsed">

    <ul v-if="!sidebarCollapsed" class="nav nav-pills flex-column mb-auto">
      <!-- Dashboard -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Dashboard' }" class="nav-link text-dark w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="table-columns" /></div><span class="flex-fill text-start">Dashboard</span>
        </router-link>
      </li>

      <!-- Vertraege -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Vertraege' }" class="nav-link text-dark w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="file-contract" /></div><span class="flex-fill text-start">Verträge</span>
        </router-link>
      </li>

      <!-- Vertragspartner -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-dark px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#vertragspartner-collapse"
          aria-expanded="true"
        >
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="users" /></div><span class="flex-fill text-start">Vertragspartner</span>
        </button>
        
        <div class="collapse show" id="vertragspartner-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item" >
              <router-link :to="{ name: 'Vertragspartner' }" class="nav-link text-dark d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Vertragspartner
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Personaldaten' }" class="nav-link text-dark w-100 d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Mitarbeiter
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- Vorlagen -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-dark px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#vorlagen-collapse"
          aria-expanded="true"
        >
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="file-lines" /></div><span class="flex-fill text-start">Vorlagen</span>
        </button>
        
        <div class="collapse show" id="vorlagen-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item" >
              <router-link :to="{ name: 'Vorlagen' }" class="nav-link text-dark d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Vertragsvorlagen
              </router-link>
            </li>

            <li class="nav-item" >
              <router-link :to="{ name: 'Textbausteine' }" class="nav-link text-dark d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Textbausteine
              </router-link>
            </li>

            <li class="nav-item" >
              <router-link :to="{ name: 'Textvariablen' }" class="nav-link text-dark d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Textvariablen
              </router-link>
            </li>

            <li><hr class="dropdown-divider"></li>

            <li class="nav-item" >
              <router-link :to="{ name: 'Dokumente' }" class="nav-link text-dark d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Word Vorlagen
              </router-link>
            </li>
          </ul>
        </div>
      </li>

    </ul>

    <hr v-if="!sidebarCollapsed">
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

export default defineComponent({

  setup () {
    const store = useStore();

    const user = computed(() => store.getters.authUser)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      user,
      sidebarCollapsed
    }
  },

});
</script>

<style lang="scss" scoped>
#sidebar {
  z-index: 100;
}

.sidebar-notcollapsed {
  width: 250px;
  transition: width .3s ease-in-out;
  // transition: background-color .3s ease-out;
  // background-color: #ffffff !important;
}
.sidebar-collapsed {
  width: 15px;
  transition: width .3s ease-in-out;
  transition: background-color .3s ease-in-out;
  background-color: #f1a82c !important;
}

.active {
  background: #fff !important;
  color: #f1a82c !important;
  
  // background: #212529 !important;
  // color: #fff !important;
}

.btn-toggle[aria-expanded="true"]::after {
    transform: rotate(0deg);
}

.btn-toggle::after {
    width: 1.25em;
    margin: auto 0 auto 0;
    line-height: 0;
    transition: transform .35s ease;
    transform: rotate(-90deg);
    content: url(../assets/icons/chevron-down.svg)
  }
</style>
  <!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->