import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mx-1 mb-2 p-1 bg-white shadow" }
const _hoisted_2 = { class: "col-3 my-auto" }
const _hoisted_3 = { class: "col-3 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }
const _hoisted_6 = { class: "col-1 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditVariableModal = _resolveComponent("EditVariableModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.variable.titel), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.variable.platzhalter), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.variable.herkunft), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.variable.feld), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.variable.type), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_EditVariableModal, {
        index: _ctx.variable.id,
        buttonType: 1,
        variable: _ctx.variable,
        onDeleteVariable: _ctx.deleteVariable
      }, null, 8, ["index", "variable", "onDeleteVariable"])
    ])
  ]))
}