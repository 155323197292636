import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Textbaustein, Vorlage } from '@/models/VorlageModels';


export enum ActionTypes {
  GetVorlagen = "GETVORLAGEN",
  CreateVorlage = "CREATEVORLAGE",
  UpdateVorlage = "UPDATEVORLAGE",
  DeleteVorlage = "DELETEVORLAGE",

  GetTextbausteine = "GETTEXTBAUSTEINE",
  CreateTextbaustein = "CREATETEXTBAUSTEIN",
  UpdateTextbaustein = "UPDATETEXTBAUSTEIN",
  DeleteTextbaustein = "DELETETEXTBAUSTEIN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetVorlagen](context: ActionArguments): Promise<void>
  [ActionTypes.CreateVorlage](context: ActionArguments, data: Vorlage): Promise<void>
  [ActionTypes.UpdateVorlage](context: ActionArguments, data: Vorlage): Promise<void>
  [ActionTypes.DeleteVorlage](context: ActionArguments, vorlageid: number): Promise<void>
  
  [ActionTypes.GetTextbausteine](context: ActionArguments): Promise<void>
  [ActionTypes.CreateTextbaustein](context: ActionArguments, data: Textbaustein): Promise<void>
  [ActionTypes.UpdateTextbaustein](context: ActionArguments, data: Textbaustein): Promise<void>
  [ActionTypes.DeleteTextbaustein](context: ActionArguments, vorlageid: number): Promise<void>
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Vorlagen
  async [ActionTypes.GetVorlagen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getVorlageLoading = true;
    status.vorlageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetVorlagen, new Array<Vorlage>())

    return ApiService.getAllVorlagen()
      .then(res => {
        const data: Array<Vorlage> = res.data
        commit(MutationTypes.SetVorlagen, data)
      })
      .catch(error => {
        if (error.response.status == 404) {
          status.vorlageErrorMsg = error.response.data.detail;
        }
        else {
          status.vorlageErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getVorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateVorlage]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createVorlageLoading = true;
    status.vorlageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVorlage(data)
      .then(res => {
        const data: Vorlage = res.data
        commit(MutationTypes.AddVorlage, data)
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 404) {
          status.vorlageErrorMsg = error.response.data.detail;
        }
        else {
          status.vorlageErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createVorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateVorlage]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateVorlageLoading = true;
    status.vorlageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateVorlage(data)
      .then(res => {
        const data: Vorlage = res.data
        commit(MutationTypes.AddOrUpdateVorlage, data)
      })
      .catch(error => {
        if (error.response.status == 404) {
          status.vorlageErrorMsg = error.response.data.detail;
        }
        else {
          status.vorlageErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateVorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteVorlage]({commit, dispatch, rootGetters}, vorlageid) {
    const status = rootGetters.status;
    status.deleteVorlageLoading = true;
    status.vorlageErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteVorlage(vorlageid)
      .then(res => {
        commit(MutationTypes.DeleteVorlage, vorlageid)
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 404) {
          status.vorlageErrorMsg = error.response.data.detail;
        }
        else {
          status.vorlageErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteVorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Textbausteine
  async [ActionTypes.GetTextbausteine]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getTextbausteineLoading = true;
    status.textbausteinErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetTextbausteine, new Array<Textbaustein>())

    return ApiService.getAllTextbausteine()
      .then(res => {
        const data: Array<Textbaustein> = res.data
        commit(MutationTypes.SetTextbausteine, data)
      })
      .catch(error => {
        if (error.response.status == 404) {
          status.textbausteinErrorMsg = error.response.data.detail;
        }
        else {
          status.textbausteinErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getTextbausteineLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTextbaustein]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTextbausteinLoading = true;
    status.textbausteinErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createTextbaustein(data)
      .then(res => {
        const data: Textbaustein = res.data
        commit(MutationTypes.AddTextbaustein, data)
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 404) {
          status.textbausteinErrorMsg = error.response.data.detail;
        }
        else {
          status.textbausteinErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createTextbausteinLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTextbaustein]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTextbausteinLoading = true;
    status.textbausteinErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateTextbaustein(data)
      .then(res => {
        const data: Textbaustein = res.data
        commit(MutationTypes.AddOrUpdateTextbaustein, data)
      })
      .catch(error => {
        if (error.response.status == 404) {
          status.textbausteinErrorMsg = error.response.data.detail;
        }
        else {
          status.textbausteinErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateTextbausteinLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteTextbaustein]({commit, dispatch, rootGetters}, textbausteinid) {
    const status = rootGetters.status;
    status.deleteTextbausteinLoading = true;
    status.textbausteinErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteTextbaustein(textbausteinid)
      .then(res => {
        commit(MutationTypes.DeleteTextbaustein, textbausteinid)
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 404) {
          status.textbausteinErrorMsg = error.response.data.detail;
        }
        else {
          status.textbausteinErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteTextbausteinLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}