import { MutationTree } from "vuex";
import { State } from "./state";

import { Dokument } from "@/models/DokumentModels";

export enum MutationTypes {
  SetDokumente = "SETDOKUMENTE",
  AddDokument = "ADDDOKUMENT",
  AddOrUpdateDokument = "UPDATEDOKUMENT",
  DeleteDokument = "DELETEDOKUMENT",
}

export type Mutations = {
  [MutationTypes.SetDokumente](state: State, data: Array<Dokument>): void
  [MutationTypes.AddDokument](state: State, data: Dokument): void
  [MutationTypes.AddOrUpdateDokument](state: State, data: Dokument): void
  [MutationTypes.DeleteDokument](state: State, data: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetDokumente](state, data) {
    state.dokumente = data;
    localStorage.setItem("dokumente", JSON.stringify(state.dokumente));
  },
  
  [MutationTypes.AddDokument](state, data) {
    state.dokumente.push(data)
    localStorage.setItem("dokumente", JSON.stringify(state.dokumente));
  },
  
  [MutationTypes.AddOrUpdateDokument](state, data) {
    var index = state.dokumente.findIndex(p => p.id == data.id)
    if (index != -1) {
      state.dokumente.splice(index, 1, data)
    }
    else {
      state.dokumente.push(data)
    }
    
    localStorage.setItem("dokumente", JSON.stringify(state.dokumente));
  },
  
  [MutationTypes.DeleteDokument](state, data) {
    var index = state.dokumente.findIndex(p => p.id == data)
    if (index != -1) {
      state.dokumente.splice(index, 1)
    }
    
    localStorage.setItem("dokumente", JSON.stringify(state.dokumente));
  },

}