<template>
  <div v-if="vorlageid != '0' && vorlage == null" class="alert alert-danger">
    Es wurde keine Vorlage gefunden

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div class="d-flex justify-content-between">
    <h1 v-if="editVorlage.id == 0">Neue Vorlage</h1>
    <h1 v-else>Vorlage {{ vorlage?.name }} bearbeiten</h1>
  </div>

  <!-- <pre>{{ vorlage }}</pre> -->

  <hr class="my-4">

  <LoadingSpinner v-if="loading || getTextbausteineLoading" text="... Daten werden geladen ..." />
  
  <div v-else>
    <form @submit.prevent="saveVorlage">

      <div class="row">
        <div class="col-5">
          <div class="mb-3">
            <label for="vorlageTitel" class="form-label fw-bold">Vorlage Bezeichnung</label>
            <input type="text" class="form-control" id="vorlageTitel" placeholder="Arbeitsvertrag 1" v-model="editVorlage.name">
          </div>

          <div class="mb-3">
            <label for="vorlageTitel" class="form-label fw-bold">Vertrag Titel</label>
            <input type="text" class="form-control" id="vorlageTitel" placeholder="Arbeitsvertrag" v-model="editVorlage.titel">
          </div>
        </div>
      </div>

      
      <div v-if="false">
        <hr class="my-4">

        <p><strong>Nummerierungseinstellungen</strong></p>
        <div class="row mb-3">
          <div class="col me-3">
            <p><strong>1. Ebene</strong> (Beispiel: {{nummerierung1example}})</p>

            <div class="row mb-4">
              <div class="col-5">
                <div class="d-flex align-items-center">
                  <label for="nummerierung-1-preafix" class="form-label mb-0 me-4">Präfix:</label>
                  <input type="text" class="form-control" id="nummerierung-1-preafix" v-model="nummerierungEbene1.praefix">
                </div>
              </div>

              <div class="col-5 offset-1">
                <div class="d-flex align-items-center">
                  <label for="nummerierung-1-suffix" class="form-label mb-0 me-4">Suffix:</label>
                  <input type="text" class="form-control" id="nummerierung-1-suffix" v-model="nummerierungEbene1.suffix">
                </div>
              </div>
            </div>

            <p>Darstellung:</p>
            <div class="d-flex mb-3">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" :value="1" id="nummerierung-1-arabisch" v-model="nummerierungEbene1.darstellung">
                <label class="form-check-label" for="nummerierung-1-arabisch">
                  Arabische-Zahlen (1, 2, 3, ...)
                </label>
              </div>
              
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" :value="2" id="nummerierung-1-roemisch" v-model="nummerierungEbene1.darstellung">
                <label class="form-check-label" for="nummerierung-1-roemisch">
                  Römische-Zahlen (I, II, III, ...)
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="radio" :value="3" id="nummerierung-1-buchstaben" v-model="nummerierungEbene1.darstellung">
                <label class="form-check-label" for="nummerierung-1-buchstaben">
                  Buchstaben (A, B, C, ...)
                </label>
              </div>
            </div>

            <p>Groß-Kleinschreibung:</p>
            <div class="d-flex mb-3">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" name="nummerierung-1-groß" id="nummerierung-1-groß" :value="true" v-model="nummerierungEbene1.gross">
                <label class="form-check-label" for="nummerierung-1-groß">
                  Groß (A, B, C, ... | I, II, III, ...)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="nummerierung-1-klein" id="nummerierung-1-klein" :value="false" v-model="nummerierungEbene1.gross">
                <label class="form-check-label" for="nummerierung-1-klein">
                  Klein (a, b, c, ... | i, ii, iii, ...)
                </label>
              </div>
            </div>

            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="nummerierung-1-punkt" v-model="nummerierungEbene1.punkt">
              <label class="form-check-label" for="nummerierung-1-punkt">
                Punkt hinter Nummerierung (1. | A. | I.)
              </label>
            </div>

          </div>

          <div class="col ms-3">
            <p><strong>2. Ebene</strong>  (Beispiel: {{nummerierung2example}})</p>

            <div class="row mb-4">
              <div class="col-5">
                <div class="d-flex align-items-center">
                  <label for="nummerierung-2-preafix" class="form-label mb-0 me-4">Präfix:</label>
                  <input type="text" class="form-control" id="nummerierung-2-preafix" v-model="nummerierungEbene2.praefix">
                </div>
              </div>

              <div class="col-5 offset-1">
                <div class="d-flex align-items-center">
                  <label for="nummerierung-2-suffix" class="form-label mb-0 me-4">Suffix:</label>
                  <input type="text" class="form-control" id="nummerierung-2-suffix" v-model="nummerierungEbene2.suffix">
                </div>
              </div>
            </div>

            <p>Darstellung:</p>
            <div class="d-flex mb-3">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" :value="1" id="nummerierung-2-arabisch" v-model="nummerierungEbene2.darstellung">
                <label class="form-check-label" for="nummerierung-2-arabisch">
                  Arabische-Zahlen (1, 2, 3, ...)
                </label>
              </div>
              
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" :value="2" id="nummerierung-2-roemisch" v-model="nummerierungEbene2.darstellung">
                <label class="form-check-label" for="nummerierung-2-roemisch">
                  Römische-Zahlen (I, II, III, ...)
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="radio" :value="3" id="nummerierung-2-buchstaben" v-model="nummerierungEbene2.darstellung">
                <label class="form-check-label" for="nummerierung-2-buchstaben">
                  Buchstaben (A, B, C, ...)
                </label>
              </div>
            </div>

            <p>Groß-Kleinschreibung:</p>
            <div class="d-flex mb-3">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" name="nummerierung-2-groß" id="nummerierung-2-groß" :value="true" v-model="nummerierungEbene2.gross">
                <label class="form-check-label" for="nummerierung-2-groß">
                  Groß (A, B, C, ... | I, II, III, ...)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="nummerierung-2-klein" id="nummerierung-2-klein" :value="false" v-model="nummerierungEbene2.gross">
                <label class="form-check-label" for="nummerierung-2-klein">
                  Klein (a, b, c, ... | i, ii, iii, ...)
                </label>
              </div>
            </div>

            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="nummerierung-2-punkt" v-model="nummerierungEbene2.punkt">
              <label class="form-check-label" for="nummerierung-2-punkt">
                Punkt hinter Nummerierung (1. | A. | I.)
              </label>
            </div>

            <div class="form-check mb-3">
              <input class="form-check-input" type="checkbox" id="nummerierung-1vor2" v-model="nummerierungEbene2.praefixSupEbene">
              <label class="form-check-label" for="nummerierung-1vor2">
                1. Ebene vorangestellt (1.1)
              </label>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4">

      <div class="row">
        <div class="col-4">
          <h3>Textbausteine</h3>
          
          <div class="input-group input-group-sm my-auto mb-3">
            <input v-model.trim="notSelectedSearchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
            <span class="input-group-text">
              <font-awesome-icon icon="magnifying-glass" class="p-1"/>
            </span>
          </div>

          <div v-for="textbaustein in notSelectedTextbausteine" :key="textbaustein.id" class="mb-2 bg-white shadow-sm p-2 selectable-textbaustein" @click="addToSelected(textbaustein)">
            <strong>{{ textbaustein.name }}</strong> ({{ textbaustein.titel }})
          </div>
        </div>

        <div class="col-1"></div>
        
        <div class="col-7">
          <h3>Vertrag</h3>

          <div class="input-group input-group-sm my-auto mb-3">
            <input v-model.trim="selectedSearchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
            <span class="input-group-text">
              <font-awesome-icon icon="magnifying-glass" class="p-1"/>
            </span>
          </div>

          <div
            v-for="(vorlageTextbaustein, index) in selectedTextbausteine
              .sort((a, b) => a.position - b.position)
              .filter(a => a.textbaustein.titel.trim().toLowerCase().includes(selectedSearchString.trim().toLowerCase()) || a.textbaustein.name.trim().toLowerCase().includes(selectedSearchString.trim().toLowerCase()))"
            :key="index"
            class="row mb-2"
          >
            <div v-if="selectedTextbausteine.length > 1" class="col-1 d-flex flex-column my-auto">
              <button type="button" v-if="vorlageTextbaustein.position != 1" class="btn p-0" @click="selectedMoveUp(index)"><font-awesome-icon icon="chevron-up" class="fa-sm" /></button>
              <button type="button" v-if="vorlageTextbaustein.position != selectedTextbausteine.length" class="btn p-0" @click="selectedMoveDown(index)"><font-awesome-icon icon="chevron-down" class="fa-sm" /></button>
            </div>
            <div v-else class="col-1">
            </div>

            <div class="col bg-white shadow-sm p-2 my-auto">
              <strong>{{ vorlageTextbaustein.textbaustein.name }}</strong> ({{ vorlageTextbaustein.textbaustein.titel }}) {{ vorlageTextbaustein.position }} 
              <div class="form-check mt-2 ms-2">
                <input class="form-check-input" type="checkbox" :id="'textbaustein-optional-' + index" v-model="vorlageTextbaustein.optional">
                <label class="form-check-label" :for="'textbaustein-optional-' + index">
                  Abschnitt optional
                </label>
              </div>
            </div>

            <div class="col-1 my-auto">
              <button type="button" class="btn" @click="deleteFromSelected(index)"><font-awesome-icon icon="trash" /></button>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4">

      <div v-if="errorMsg != ''" class="alert alert-danger mb-4">
        Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
      </div>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
        <button type="submit" class="btn btn-primary me-3">Speichern</button>
      </div>
    </form>

    <div v-if="editVorlage.id != 0">
      <hr class="my-4">
      <button type="button" class="btn btn-danger" @click="deleteVorlage()"><font-awesome-icon icon="trash" />Vorlage löschen</button>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';
import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import CreateTextbausteinRow from "@/components/Vorlagen/CreateTextbausteinRow.vue"

import { Textbaustein, Vorlage, VorlageTextbaustein, NummerierungEinstellung } from '@/models/VorlageModels';

export default defineComponent({
  name: "EditVorlageView",

  components: {
    LoadingSpinner,
    CreateTextbausteinRow,
  },

  props: {
    vorlageid: {
      type: String as PropType<string>,
      default: "0"
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const getTextbausteineLoading = computed(() => store.getters.status.getTextbausteineLoading)
    const textbausteinErrorMsg = computed(() => store.getters.status.textbausteinErrorMsg)

    const textbausteine = computed(() => store.getters.textbausteine)

    const nummerierung1example = computed(() => {
      let nummerierung = "";
      if (nummerierungEbene1.value.praefix != "") {
        nummerierung += nummerierungEbene1.value.praefix + " "
      }

      if (nummerierungEbene1.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene1.value.darstellung == 2) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene1.value.darstellung == 3) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene1.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene1.value.suffix != "") {
        nummerierung += " " + nummerierungEbene1.value.suffix
      }

      return nummerierung
    })

    const nummerierung2example = computed(() => {
      let nummerierung = "";
      if (nummerierungEbene2.value.praefix != "") {
        nummerierung += nummerierungEbene2.value.praefix + " "
      }

      if (nummerierungEbene2.value.praefixSupEbene) {
        if (nummerierungEbene1.value.darstellung == 1) {
          nummerierung += "1."
        } else if (nummerierungEbene1.value.darstellung == 2) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "I."
          } else {
            nummerierung += "i."
          }
        } else if (nummerierungEbene1.value.darstellung == 3) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "A."
          } else {
            nummerierung += "a."
          }
        }
      }

      if (nummerierungEbene2.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene2.value.darstellung == 2) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene2.value.darstellung == 3) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene2.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene2.value.suffix != "") {
        nummerierung += " " + nummerierungEbene2.value.suffix
      }

      return nummerierung
    })

    const notSelectedSearchString = ref("");
    const notSelectedTextbausteine = computed(() => textbausteine.value
    .filter((textbaustein: Textbaustein) => !selectedTextbausteine.value.map(t => t.textbausteinid).includes(textbaustein.id))
    .filter((textbaustein: Textbaustein) => textbaustein.name.trim().toLowerCase().includes(notSelectedSearchString.value.trim().toLowerCase()) || textbaustein.titel.trim().toLowerCase().includes(notSelectedSearchString.value.trim().toLowerCase()))
    .sort((a: Textbaustein, b: Textbaustein) => a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase()))
  );
  
    const selectedSearchString = ref("");
    const selectedTextbausteine = ref(new Array<VorlageTextbaustein>());

    function addToSelected(textbaustein: Textbaustein) {
      if (!selectedTextbausteine.value.map(t => t.textbausteinid).includes(textbaustein.id)) {
        selectedTextbausteine.value.push(new VorlageTextbaustein(editVorlage.value.id, textbaustein, selectedTextbausteine.value.length + 1))
      }

    }

    function deleteFromSelected(index: number) {
      selectedTextbausteine.value.splice(index, 1)
      reorderSelected()
    }

    function selectedMoveUp(index: number) {
      if (index > 0) {
        selectedTextbausteine.value[index - 1].position += 1
        selectedTextbausteine.value[index].position -= 1
      }
    }

    function selectedMoveDown(index: number) {
      if (index < selectedTextbausteine.value.length - 1) {
        selectedTextbausteine.value[index + 1].position -= 1
        selectedTextbausteine.value[index].position += 1
      }
    }

    function reorderSelected() {
      for (let [index, selected] of selectedTextbausteine.value.sort((a, b) => a.position - b.position).entries()) {
        selected.position = index + 1
      }
    }

    const vorlage = computed(() => store.getters.vorlagen.find((v: Vorlage) => v.id == Number.parseInt(props.vorlageid)))
    const tmpVorlage = ref<Vorlage | null>(vorlage.value != null ? JSON.parse(JSON.stringify(vorlage.value)) as Vorlage : null)
    if (tmpVorlage.value != null) {
      if (tmpVorlage.value.nummerierungEinstellungen.length < 2) {
        const ebene1 = tmpVorlage.value.nummerierungEinstellungen.find(n => n.ebene == 1)
        if (ebene1 == undefined) {
          tmpVorlage.value.nummerierungEinstellungen.push(new NummerierungEinstellung(tmpVorlage.value.id, null, 1, "", "", 1, true, true, false));
        }
        const ebene2 = tmpVorlage.value.nummerierungEinstellungen.find(n => n.ebene == 2)
        if (ebene2 == undefined) {
          tmpVorlage.value.nummerierungEinstellungen.push(new NummerierungEinstellung(tmpVorlage.value.id, null, 2, "", "", 1, true, false, true));
        }
      }
    }


    const editVorlage = ref<Vorlage>(tmpVorlage.value != null ? tmpVorlage.value : new Vorlage())
    selectedTextbausteine.value = editVorlage.value.vorlageTextbausteine;
    if (selectedTextbausteine.value.length > 0 && selectedTextbausteine.value.map(t => t.position).includes(0)) {
        reorderSelected()
      }
    const nummerierungEbene1 = ref(editVorlage.value.nummerierungEinstellungen.find(n => n.ebene == 1) || new NummerierungEinstellung(editVorlage.value.id, null, 1, "", "", 1, true, true, false))
    const nummerierungEbene2 = ref(editVorlage.value.nummerierungEinstellungen.find(n => n.ebene == 2) || new NummerierungEinstellung(editVorlage.value.id, null, 2, "", "", 1, true, false, true))

    const loading = computed(() => store.getters.status.createVorlageLoading || store.getters.status.updateVorlageLoading  || store.getters.status.deleteVorlageLoading)
    const errorMsg = computed(() => store.getters.status.vorlageErrorMsg)

    async function saveVorlage() {
      editVorlage.value.vorlageTextbausteine = selectedTextbausteine.value
      editVorlage.value.nummerierungEinstellungen = [nummerierungEbene1.value, nummerierungEbene2.value]

      if (vorlage.value != null) {
        await store.dispatch(VorlageActionTypes.UpdateVorlage, editVorlage.value)
      }
      else {
        await store.dispatch(VorlageActionTypes.CreateVorlage, editVorlage.value)
      }

      if (errorMsg.value == "") {
        cancel();
      }
    }

    function deleteVorlage() {
      store.dispatch(VorlageActionTypes.DeleteVorlage, vorlage.value.id)
      .then(() => {
        if (errorMsg.value == "") {
          router.push({ name: "Vorlagen" })
        }
      })
    }

    function cancel() {
      router.back();
    }

    function loadTextbausteine() {
      store.dispatch(VorlageActionTypes.GetTextbausteine, undefined)
    }


    return {
      store,
      router,

      getTextbausteineLoading,
      textbausteinErrorMsg,
      loadTextbausteine,
      textbausteine,

      nummerierung1example,
      nummerierung2example,

      notSelectedSearchString,
      notSelectedTextbausteine,
      selectedSearchString,
      selectedTextbausteine,
      addToSelected,
      deleteFromSelected,
      selectedMoveUp,
      selectedMoveDown,

      vorlage,
      editVorlage,
      nummerierungEbene1,
      nummerierungEbene2,
      loading,
      errorMsg,

      cancel,
      saveVorlage,
      deleteVorlage,
    }
  },

  mounted () {
    if (this.textbausteine.length == 0) {
      this.loadTextbausteine();
    }

    if (this.store.getters.variablen.length == 0) {
      this.store.dispatch(VariablenActionTypes.GetVariablen, undefined)
    }
  }
})
</script>

<style scoped>
.selectable-textbaustein:hover {
  cursor: pointer;
  background: #fff4af !important;
  color: #000000;
}

</style>