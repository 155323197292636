<template>
  <div class="mx-1 mb-2 p-2 bg-white shadow">
    <div v-if="editAbschnittMode">

      <div class="mb-3">
        <label :for="'abschnittTitel-' + abschnitt.id" class="form-label fw-bold">Titel</label>
        <input type="text" class="form-control" :id="'abschnittTitel-' + abschnitt.id" v-model="editAbschnitt.titel">
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox"  :id="'abschnittNummberierung-' + abschnitt.id" v-model="editAbschnitt.nummerierungSetting">
        <label class="form-check-label" :for="'abschnittNummberierung-' + abschnitt.id">
          Abschnitt nummerieren
        </label>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-10 fw-bold my-auto">{{ abschnitt.titel }}</div>

      <div class="col-2 my-auto d-flex justify-content-end">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary" @click="toggleCollapse()"><font-awesome-icon icon="magnifying-glass-plus" /></button>
          <button type="button" class="btn btn-secondary" @click="setEditAbschnitt()" ><font-awesome-icon icon="pen" /></button>
        </div>
      </div>
    </div>
      
    <div class="mt-3 pt-2 border-top border-secondary" :class="{ 'collapse': isCollapsed }">
      <div v-if="editAbschnittMode">
        <div class="row mb-3">
          <div class="col-3">
            <strong>Variablen</strong>
            <div v-for="input of editAbschnitt.inputs" :key="input.key" class="mb-2 border-bottom p-2 bg-white shadow-sm">
              <div class="mb-2">
                <label :for="'variable-edit-' + input.key" class="form-label">{{input.key}}</label>
                <input type="text" class="form-control" :id="'variable-edit-' + input.key" v-model="input.value">
              </div>
            </div>

            <!-- <button class="btn btn-primary" @click><font-awesome-icon icon="plus" /> hinzufügen</button> -->

          </div>

          <div class="col-9">
            <TextEditor v-model="editAbschnitt.text" />
          </div>
        </div>

        <div v-for="subabschnitt in editAbschnitt.abschnitte" :key="subabschnitt.id" class="ms-4 mb-3 border-top pt-3">
          <div class="mb-3">
            <label :for="'abschnittTitel-' + subabschnitt.id" class="form-label fw-bold">Titel</label>
            <input type="text" class="form-control" :id="'abschnittTitel-' + subabschnitt.id" v-model="subabschnitt.titel">
          </div>

          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox"  :id="'abschnittNummberierung-' + subabschnitt.id" v-model="subabschnitt.nummerierungSetting">
            <label class="form-check-label" :for="'abschnittNummberierung-' + subabschnitt.id">
              Abschnitt nummerieren
            </label>
          </div>

          <div class="row">
            <div class="col-3">
              <strong>Variablen</strong>
              <div v-for="input of subabschnitt.inputs" :key="input.key" class="mb-2 border-bottom p-2 bg-white shadow-sm">
                <div class="mb-2">
                  <label :for="'variable-edit-' + input.key" class="form-label">{{input.key}}</label>
                  <input type="text" class="form-control" :id="'variable-edit-' + input.key" v-model="input.value">
                </div>
              </div>

              <!-- <button class="btn btn-primary" @click><font-awesome-icon icon="plus" /> hinzufügen</button> -->

            </div>

            <div class="col-9">
              <TextEditor v-model="subabschnitt.text" />
            </div>
          </div>
        </div>

        <div class="pt-1 mt-5">
          <LoadingSpinner v-if="updateAbschnittLoading" text="... Daten werden gespeichert ..." />

          <div v-else-if="!updateAbschnittLoading && abschnittErrorMsg != ''" class="alert alert-danger">
            Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut. 
          </div>

          <div v-if="!updateAbschnittLoading" class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" @click="cancelEditAbschnitt()">Abbrechen</button>
            <button class="btn btn-primary" @click="saveAbschnitt()">Speichern</button>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-html="abschnitt.text"></div>
        
        <div v-for="subabschnitt in abschnitt.abschnitte" :key="subabschnitt.id" class="ms-4 mb-3">
          <p class="fw-bold">{{ subabschnitt.titel }}</p>
          <div v-html="subabschnitt.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';

import LoadingSpinner from '../LoadingSpinner.vue';
// import CreateVariableModal from './CreateVariableModal.vue';
import TextEditor from '../TextEditor/TextEditor.vue';

import { Textbaustein } from '@/models/VorlageModels';
import { TextAbschnitt, Vertrag } from '@/models/VertragModels';

export default defineComponent({
  name: "TextbausteinRow",

  components: {
    LoadingSpinner,
    // CreateVariableModal,
    TextEditor
  },

  props: {
    abschnitt: {
      type: Object as PropType<TextAbschnitt>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    // const getVariablenLoading = computed(() => store.getters.status.getVariablenLoading);
    // const variablenErrorMsg = computed(() => store.getters.variablenErrorMsg); 
    // const variablen = computed(() => store.getters.variablen);
    
    const isCollapsed = ref(true)
    function toggleCollapse () {
      isCollapsed.value = !isCollapsed.value
    }
    
    const updateAbschnittLoading = computed(() => store.getters.status.updateAbschnittLoading)
    const abschnittErrorMsg = computed(() => store.getters.status.abschnittErrorMsg)

    const editAbschnitt = ref(JSON.parse(JSON.stringify(props.abschnitt)))

    const editAbschnittMode = ref(false)
    function setEditAbschnitt() {
      editAbschnittMode.value = true;
      isCollapsed.value = false;
    }

    function cancelEditAbschnitt() {
      editAbschnittMode.value = JSON.parse(JSON.stringify(props.abschnitt));
      editAbschnittMode.value = false;
    }

    function saveAbschnitt() {
      editAbschnitt.value.text = sanitizeHTML(editAbschnitt.value.text)
      store.dispatch(VertragActionTypes.UpdateAbschnitt, editAbschnitt.value)
      .then(() => {
        if (abschnittErrorMsg.value == "") {
          editAbschnittMode.value = false
          editAbschnitt.value = JSON.parse(JSON.stringify(props.abschnitt))
        }
      })
    }

    function sanitizeHTML(htmlString: string): string {
      // Regular expression to remove <script> tags and JavaScript code
      const sanitizedHTML = htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
      return sanitizedHTML;
    }

    return {
      isCollapsed,
      toggleCollapse,
      updateAbschnittLoading,
      abschnittErrorMsg,
      editAbschnitt,
      editAbschnittMode,
      setEditAbschnitt,
      cancelEditAbschnitt,
      saveAbschnitt,
    }
  }

})
</script>