import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Dokument } from "@/models/DokumentModels";


export type Getters = {
  dokumente(state: State): Array<Dokument>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  dokumente(state) {
    return state.dokumente;
  },
}
