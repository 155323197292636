export class Vorlage {
  id: number;
  name: string;
  titel: string;

  nummerierungEinstellungen: Array<NummerierungEinstellung>;

  vorlageTextbausteine: Array<VorlageTextbaustein>
  adate: Date;
  mdate: Date;
  archivBenutzerId: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.titel = "";

    this.nummerierungEinstellungen = [
      new NummerierungEinstellung(this.id, null, 1, "", "", 1, true, true, false),
      new NummerierungEinstellung(this.id, null, 2, "", "", 1, true, false, true),
    ];

    this.vorlageTextbausteine = new Array<VorlageTextbaustein>();
    this.adate = new Date;
    this.mdate = new Date;
    this.archivBenutzerId = 0
  }
}

export class NummerierungEinstellung {
  id: number;
  vorlageid: number | null;
  vertragid: number | null;
  ebene: number;
  praefix: string;
  suffix: string;
  darstellung: number; // 1 = 1, 2 = I, 3 = A
  gross: boolean;
  punkt: boolean;
  praefixSupEbene: boolean;

  constructor(vorlageid: number | null, vertragid: number | null, ebene: number, praefix: string = "", suffix: string = "", darstellung: number = 1, gross: boolean = true, punkt: boolean = true, praefixSupEbene: boolean = false) {
    this.id = 0;
    this.vorlageid = vorlageid;
    this.vertragid = vertragid;
    this.ebene = ebene;
    this.praefix = praefix;
    this.suffix = suffix;
    this.darstellung = darstellung;
    this.gross = gross;
    this.punkt = punkt;
    this.praefixSupEbene = praefixSupEbene;
  }
}

export class VorlageTextbaustein {
  id: number;
  vorlageid: number;
  textbausteinid: number;
  textbaustein: Textbaustein
  position: number;
  optional: boolean;
  adate: Date;
  mdate: Date;
  archivBenutzerId: number;

  constructor(vorlageid: number, textbaustein: Textbaustein, position: number) {
    this.id = 0;
    this.vorlageid = vorlageid;
    this.textbausteinid = textbaustein.id;
    this.textbaustein = textbaustein;
    this.position = position;
    this.optional = false;
    this.adate = new Date;
    this.mdate = new Date;
    this.archivBenutzerId = 0
  }
}

export class Textbaustein {
  id: number;
  name: string;
  titel: string;
  text: string;
  position: number;
  nummerierungSetting: boolean;
  ownerid: number | null;
  abschnitte: Array<Textbaustein>;
  adate: Date;
  mdate: Date;
  archivBenutzerId: number;

  constructor() {
    this.id = 0;
    this.name = "";
    this.titel = "";
    this.text = "";
    this.position = 1;
    this.nummerierungSetting = true;
    this.ownerid = null;
    this.abschnitte = new Array<Textbaustein>();
    this.adate = new Date;
    this.mdate = new Date;
    this.archivBenutzerId = 0
  }
}

export class Variable {
  id: number;
  titel: string;
  platzhalter: string;
  herkunft: string;
  feld: string;
  type: string;
  value: string;

  minLength: number;
  maxLength: number;
  validationType: string; // "text", "int", "float", "currency"

  adate: Date;
  mdate: Date;
  archivBenutzerId: number;

  constructor() {
    this.id = 0;
    this.titel = "";
    this.platzhalter = "";
    this.herkunft = "";
    this.feld = "";
    this.type = "text";
    this.value = "";

    this.minLength = 0;
    this.maxLength = 80;
    this.validationType = "text";

    this.adate = new Date;
    this.mdate = new Date;
    this.archivBenutzerId = 0
  }
}