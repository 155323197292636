<template>
  <div class="row mb-2 py-1 bg-white shadow-sm mx-1">
    <div class="col-3 my-auto">{{ dokument.titel }}</div>
    <div class="col-3 my-auto">{{ dokument.kategorie }}</div>
    <div class="col-5 my-auto">{{ dokument.fileName }}</div>
    <div class="col-1 d-flex align-items-center justify-content-end">
      <button type="button" class="btn btn-secondary btn-sm me-1" @click="downloadDokument()">
        <font-awesome-icon icon="file-download" />
      </button>

      <EditDokumentModal :dokument="dokument" :index="dokument.id" :buttonStyle="1" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as DokumenteActionTypes } from '@/store/modules/Dokumente/actions';

import EditDokumentModal from '@/components/Dokumente/EditDokumentModal.vue';

import { Dokument, DownloadDokumentRequest } from '../../models/DokumentModels';


export default defineComponent({
  name: "DokumentRow",

  components: {
    EditDokumentModal
  },

  props: {
    dokument: {
      type: Object as PropType<Dokument>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()

    function downloadDokument() {
      store.dispatch(DokumenteActionTypes.DownloadDokumentById, new DownloadDokumentRequest(props.dokument.id, props.dokument.fileName))
    }

    return {
      downloadDokument
    }
  }
})
</script>