import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mx-1 mb-2 p-2 bg-white shadow" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-3 fw-bold my-auto" }
const _hoisted_4 = { class: "col-6 my-auto" }
const _hoisted_5 = { class: "col-1 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto d-flex justify-content-end" }
const _hoisted_7 = {
  class: "btn-group",
  role: "group"
}
const _hoisted_8 = { class: "fw-bold" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "fw-bold fst-italic" }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.textbaustein.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.textbaustein.titel), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.abschnitteCount), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCollapse()))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass-plus" })
          ]),
          (_ctx.showEdit)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'EditTextbaustein', query: { textbausteinid: _ctx.textbaustein.id } },
                class: "btn btn-secondary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "pen" })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["mt-3 pt-2 border-top border-secondary", { 'collapse': _ctx.isCollapsed }])
    }, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.textbaustein.titel), 1),
      _createElementVNode("div", {
        innerHTML: _ctx.textbaustein.text
      }, null, 8, _hoisted_9),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textbaustein.abschnitte, (abschnitt) => {
        return (_openBlock(), _createElementBlock("div", {
          key: abschnitt.id,
          class: "mt-3 ms-4"
        }, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(abschnitt.titel), 1),
          _createElementVNode("div", {
            innerHTML: abschnitt.text
          }, null, 8, _hoisted_11)
        ]))
      }), 128))
    ], 2)
  ]))
}