import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mx-1 mb-2 p-2 bg-white shadow" }
const _hoisted_2 = { class: "col-4 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }
const _hoisted_6 = { class: "col-1 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.vertrag.titel), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.mitarbeiter?.vorname) + " " + _toDisplayString(_ctx.mitarbeiter?.name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.vertrag.personaldatenGuid), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.dateToDateConverter(_ctx.vertrag.adate)), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.vertrag.textabschnitte.filter(a => a.ownerid == null || a.ownerid == 0).length), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: {name: 'DetailsVertrag', query: {vertragid: _ctx.vertrag.id, guid: _ctx.vertrag.personaldatenGuid}},
        class: "btn btn-primary btn-sm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass-plus" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}