import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2 py-1 bg-white shadow-sm mx-1" }
const _hoisted_2 = { class: "col-2 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-7 my-auto" }
const _hoisted_5 = { class: "col-1 d-flex align-items-center justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.personaldaten.name), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.personaldaten.vorname), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.personaldaten.guid), 1),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: {name: 'DetailsPersonaldaten', params: {guid: _ctx.personaldaten.guid}},
        class: "btn btn-primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}