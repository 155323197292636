<template>
  <div class="mb-3">
    <strong>{{ abschnitt.titel }}</strong>
    <div v-for="variable in textVariablen" class="mb-3">

      <TextInput
          v-if="variable.type == 'text'"
          :index="0"
          :modelValue="inputData[variable.platzhalter]"
          :label="variable.titel"
          :min="variable.minLength"
          :max="variable.maxLength"
          :inputType="variable.validationType" />

      <div v-else>
        <label :for="variable.titel + '-input-' + index" class="form-label">{{ variable.titel }}</label>
        <input :type="variable.type" class="form-control" :id="variable.titel + '-input-' + index" v-model="inputData[variable.platzhalter]">
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useStore } from "@/store";
import { useDateConverter } from "@/utils/date_converter";

import TextInput from "../FormInputs/TextInput.vue";

import { Textbaustein, Variable } from "@/models/VorlageModels"
import { Input } from "@/models/VertragModels"
import { Personaldaten } from "@/models/PersonaldatenModels";

export default defineComponent({
  name: "AbschnittInput",

  components: {
    TextInput
  },

  props: {
    abschnitt: {
      type: Object as PropType<Textbaustein>,
      required: true
    },

    personaldaten: {
      type: Object, // as PropType<Personaldaten>,
      required: true
    },

    vertrag: {
      type: Object,
      required: true
    },
    
    index: {
      type: Number,
      required: true
    }
  },

  emits: ["getInputData"],

  setup(props, { emit }) {
    const store = useStore();

    const { dateToDateConverter } = useDateConverter();

    const variablen = computed(() => store.getters.variablen)

    const inputData = ref<{[key: string]: any}>({})

    const textVariablen = computed(() => {
      const variableRegex =  /&lt;&lt;([^&]+)&gt;&gt;/g;
      const variables = new Array<Variable>();
      let match;

      while ((match = variableRegex.exec(props.abschnitt.text)) !== null) {
        const variableText = `<<${match[1]}>>`
        const variable = variablen.value.find((v: Variable) => v.platzhalter == variableText)

        if (variable != null) {
          variables.push(variable);
          var value = null
          
          if (variable.herkunft == "Personaldaten" && variable.feld != null && variable.feld != "") {
            if (variable.type == "date") {
              value = dateStringConverter(props.personaldaten[variable.feld]);
            }
            else {
              value = props.personaldaten[variable.feld];
            }
          }

          if (variable.herkunft == "Vertrag" && variable.feld != null && variable.feld != "") {
            if (variable.feld.includes(".")) {
              const felder = variable.feld.split(".");
              if (variable.type == "date") {
                value = dateStringConverter(props.vertrag[felder[0]][felder[1]]);
              }
              else {
                value = props.vertrag[felder[0]][felder[1]];
              }
            }
            else {
              if (variable.type == "date") {
                value = dateStringConverter(props.vertrag[variable.feld]);
              }
              else {
                value = props.vertrag[variable.feld];
              }
            }
          }

          inputData.value[variableText] = value;
        }
      }

      return variables;
    })

    function dateStringConverter(dateString: string): string {
      const date = new Date(dateString);
      return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    }

    function getInputData() {
      var response = new Array<Input>();

      emit("getInputData", response)
    }

    function returnInputData(): Array<Input> {
      var response = new Array<Input>();

      for (const [key, value] of Object.entries(inputData.value)) {
        const variable = textVariablen.value.find((v: Variable) => v.platzhalter == key)
        let data = value != null ? value.toString() : ""
        if (variable != null && value != null && variable.type == "date") {
          data = dateToDateConverter(data);
        }


        const input = new Input(0, key, data)
        response.push(input)
      }

      return response;
    }

    return {
      textVariablen,
      inputData,
      getInputData,
      returnInputData
    }
  }
})
</script>