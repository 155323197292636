<template>
  <div class="row mx-1 mb-3 p-1 bg-white shadow">
    <div class="col-10 my-auto">{{ textbaustein.titel }}</div>
    <div class="col-2 my-auto d-flex justify-content-end">
      <div class="btn-group" role="group" aria-label="Textbaustein Funktionen">
        <button class="btn btn-primary" @click="showModal()"><font-awesome-icon icon="pen"/></button>
        <button class="btn btn-secondary" @click="deleteTextbaustein()"><font-awesome-icon icon="trash"/></button>
      </div>
    </div>
  </div>


  <!-- Edit TextbausteinModal -->
  <div
    class="modal fade"
    :id="'editTextbausteinModal' + index"
    tabindex="-1"
    :aria-labelledby="'editTextbausteinModal' + index"
    aria-hidden="true"
    ref="editTextbausteinModalRef"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white py-2">
          <span class="modal-title my-auto" :id="'editTextbausteinModalLabel' + index">
            Textbaustein bearbeiten
          </span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'editTextbausteinModalBody' + index">
          {{ textbaustein }}
          <div class="mb-3">
            <label :for="'textbausteinName-' + index" class="form-label fw-bold">Name</label>
            <input type="text" class="form-control" :id="'textbausteinName-' + index" v-model="textbaustein.name">
          </div>

          <div class="mb-3">
            <label :for="'textbausteinTitel-' + index" class="form-label fw-bold">Titel</label>
            <input type="text" class="form-control" :id="'textbausteinTitel-' + index" v-model="textbaustein.titel">
          </div>

          <div class="row mb-4">
            <div class="col-2">
              <LoadingSpinner v-if="getVariablenLoading" />

              <div v-else-if="variablen.length == 0" class="alert alert-info">
                Es wurden keine Variablen gefunden.
              </div>

              <div v-else>
                <strong>Variablen</strong>
                <div v-for="variable in variablen" :key="variablen.id" class="mb-2 border-bottom p-2 bg-white shadow-sm">{{ variable.variable }}</div>
              </div>

              <div v-if="!getVariablenLoading" class="mt-3">
                <EditVariableModal />
              </div>
            </div>

            <div class="col-10">
              <TextEditor v-model="textbaustein.text" />
            </div>
          </div>
          
          <!-- <hr class="my-3"> -->

        </div>
        
        <div class="modal-footer py-2 mt-3">
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="closeModal()"
              >
              Speichern
            </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { Modal } from 'bootstrap';

import { ActionTypes as VariablenActionTypes } from "@/store/modules/Variablen/actions";

import TextEditor from "@/components/TextEditor/TextEditor.vue"
import EditVariableModal from './EditVariableModal.vue';

import { Textbaustein } from '@/models/VorlageModels';
import { useStore } from 'vuex';
import LoadingSpinner from '../LoadingSpinner.vue';

export default defineComponent({
  name: "TextbausteinRow",

  components: {
    TextEditor,
    EditVariableModal
  },

  props: {
    textbaustein: {
      type: Object as PropType<Textbaustein>,
      required: true
    },

    index: {
      type: Number,
      required: true
    }
  },

  emits: ["deleteTextbaustein"],

  setup(props, { emit }) {
    const store = useStore();
    
    const getVariablenLoading = computed(() => store.getters.status.getVariablenLoading)
    const variablenErrorMsg = computed(() => store.getters.status.variablenErrorMsg)

    const variablen = computed(() => store.getters.variablen)
    function loadVariablen() {
      store.dispatch(VariablenActionTypes.GetVariablen, undefined)
    }

    const editTextbausteinModalRef = ref()
    function closeModal() {
      Modal.getInstance(editTextbausteinModalRef.value)?.hide();
    }
    function showModal() {
      if (editTextbausteinModalRef.value) {
        new Modal(editTextbausteinModalRef.value).show();
      }
    }

    function deleteTextbaustein() {
      emit("deleteTextbaustein", props.index)
    }

    return {
      getVariablenLoading,
      variablenErrorMsg,
      variablen,
      loadVariablen,
      editTextbausteinModalRef,
      closeModal,
      showModal,
      deleteTextbaustein,
    }
  },

  mounted() {
    if (this.variablen.length == 0) {
      this.loadVariablen();
    }
  }
})
</script>

<style scoped>
.modal-content {
  background: rgb(242, 243, 248);
}

/* .modal-body {
  background: rgb(242, 243, 248);
}

.modal-footer {
  background: rgb(242, 243, 248);
} */
</style>