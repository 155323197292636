import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["type", "id", "onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("strong", null, _toDisplayString(_ctx.abschnitt.titel), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textVariablen, (variable) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (variable.type == 'text')
          ? (_openBlock(), _createBlock(_component_TextInput, {
              key: 0,
              index: 0,
              modelValue: _ctx.inputData[variable.platzhalter],
              label: variable.titel,
              min: variable.minLength,
              max: variable.maxLength,
              inputType: variable.validationType
            }, null, 8, ["modelValue", "label", "min", "max", "inputType"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("label", {
                for: variable.titel + '-input-' + _ctx.index,
                class: "form-label"
              }, _toDisplayString(variable.titel), 9, _hoisted_4),
              _withDirectives(_createElementVNode("input", {
                type: variable.type,
                class: "form-control",
                id: variable.titel + '-input-' + _ctx.index,
                "onUpdate:modelValue": ($event: any) => ((_ctx.inputData[variable.platzhalter]) = $event)
              }, null, 8, _hoisted_5), [
                [_vModelDynamic, _ctx.inputData[variable.platzhalter]]
              ])
            ]))
      ]))
    }), 256))
  ]))
}