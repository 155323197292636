<template>
  <h1 v-if="vertragspartner != null">Partner bearbeiten</h1>
  <h1 v-else >Neuer Partner</h1>

  <LoadingSpinner v-if="loading" />

  <div v-else>
    <form @submit.prevent="saveVertragspartner">

      <div class="mb-3">
        <label for="unternehmen" class="form-label">Unternehmen</label>
        <input type="text" class="form-control" id="unternehmen" v-model="editVertragspartner.unternehmen">
      </div>

      <hr class="my-2">
      
      <strong>Ansprechpartner</strong>
      <div class="mb-3">
        <label for="vorname" class="form-label">Vorname</label>
        <input type="text" class="form-control" id="vorname" v-model="editVertragspartner.vorname">
      </div>

      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="editVertragspartner.name">
      </div>
      
      
      <hr class="my-2">

      <strong>Anschrift</strong>
      <div class="mb-3">
        <label for="strasse" class="form-label">Straße & Hausnummer</label>
        <input type="text" class="form-control" id="strasse" v-model="editVertragspartner.strasse">
      </div>
      
      <div class="mb-3">
        <label for="plz" class="form-label">PLZ</label>
        <input type="text" class="form-control" id="plz" v-model="editVertragspartner.plz">
      </div>
      
      <div class="mb-3">
        <label for="ort" class="form-label">Ort</label>
        <input type="text" class="form-control" id="ort" v-model="editVertragspartner.ort">
      </div>

      <div class="mb-3">
        <label for="Land" class="form-label">Land</label>
        <input type="text" class="form-control" id="Land" v-model="editVertragspartner.Land">
      </div>

      <hr class="my-2">

      <div class="mb-3">
        <label for="notizen" class="form-label">Notizen</label>
        <textarea class="form-control" id="notizen" rows="3" v-model="editVertragspartner.notizen"></textarea>
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger">
        Es ist ein Fehler aufgetreten:
        {{ errorMsg }}
      </div>

      <hr class="my-4">

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
        <button type="submit" class="btn btn-primary">Speichern</button>
      </div>
    </form>

    <div v-if="vertragspartner != null">
      <hr class="my-4">

      <button type="button" class="btn btn-danger" @click="deleteVertragspartner()"><font-awesome-icon icon="trash" /> Vertragspartner löschen</button>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import { ActionTypes as VertragspartnerActionTypes } from "@/store/modules/Vertragspartner/actions";

import { Vertragspartner } from "@/models/VertragspartnerModels";

import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "EditVertragspartnerView",

  components: {
    LoadingSpinner,
  },

  props: {
    vertragspartnerid: {
      type: String as PropType<string>,
      default: ""
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.createVertragspartnerLoading || store.getters.status.updateVertragspartnerLoading || store.getters.status.deleteVertragspartnerLoading)
    const errorMsg = computed(() => store.getters.status.vertragspartnerErrorMsg)

    const vertragspartner = computed(() => store.getters.vertragspartner.find((v: Vertragspartner) => v.id == parseInt(props.vertragspartnerid)))
    const editVertragspartner = ref(vertragspartner.value != null ? JSON.parse(JSON.stringify(vertragspartner.value)) : new Vertragspartner())


    function saveVertragspartner() {
      if (vertragspartner.value != null) {
        store.dispatch(VertragspartnerActionTypes.UpdateVertragspartner, editVertragspartner.value)
        .then(() => {
          if (errorMsg.value == "") {
            router.push({name: "DetailsVertragspartner", query: {vertragspartnerid: props.vertragspartnerid}})
          }
        })
      }
      else {
        store.dispatch(VertragspartnerActionTypes.CreateVertragspartner, editVertragspartner.value)
        .then(() => {
          if (errorMsg.value == "") {
            router.push({name: "Vertragspartner"})
          }
        })
      }
    }


    function deleteVertragspartner () {
      if (vertragspartner.value != null) {
        store.dispatch(VertragspartnerActionTypes.DeleteVertragspartner, vertragspartner.value.id)
        .then(() => {
          if (errorMsg.value == "") {
            router.push({name: "Vertragspartner"})
          }
        })
      } 
    }

    function cancel() {
      router.back();
    }

    return {
      store,

      loading,
      errorMsg,

      vertragspartner,
      editVertragspartner,

      saveVertragspartner,
      deleteVertragspartner,
      cancel
    }
  },

})
</script>