<template>
  <div class="row mx-1 mb-2 p-2 bg-white shadow">
    <div class="col-4 my-auto">{{ vertrag.titel }}</div>
    <div class="col-2 my-auto">{{ mitarbeiter?.vorname }} {{ mitarbeiter?.name }}</div>
    <div class="col-2 my-auto">{{ vertrag.personaldatenGuid }}</div>
    <div class="col-2 my-auto">{{ dateToDateConverter(vertrag.adate) }}</div>
    <div class="col-1 my-auto">{{ vertrag.textabschnitte.filter(a => a.ownerid == null || a.ownerid == 0).length }}</div>
    <div class="col-1 my-auto d-flex justify-content-end">
      <router-link :to="{name: 'DetailsVertrag', query: {vertragid: vertrag.id, guid: vertrag.personaldatenGuid}}" class="btn btn-primary btn-sm"><font-awesome-icon icon="magnifying-glass-plus"/></router-link>
    </div>
  </div>

</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useDateConverter } from '@/utils/date_converter';

import { Vertrag } from '@/models/VertragModels';
import { Personaldaten } from '@/models/PersonaldatenModels';

export default defineComponent({
  name: "VertragRow",

  props: {
    vertrag: {
      type: Object as PropType<Vertrag>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    
    const mitarbeiter = computed(() => store.getters.personaldaten.find((m: Personaldaten) => m.guid === props.vertrag.personaldatenGuid));
    const { dateToDateConverter } = useDateConverter();

    return {
      mitarbeiter,
      dateToDateConverter
    }
  }

})
</script>