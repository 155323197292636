

export function useDateConverter() {

  function dateToDateConverter(date: Date): string {
    if (typeof date === "string") {
      date = new Date(date);
    }

    return ("0" + date.getDate()).slice(-2) + "." +("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
  }

  return {
    dateToDateConverter
  };  
}