<template>
  <div class="row mx-1 mb-2 p-2 bg-white shadow" @contextmenu.prevent="openContextMenu">
    <div class="col-3 my-auto fw-bold">{{ vorlage.name }}</div>
    <div class="col-6 my-auto">{{ vorlage.titel }}</div>
    <div class="col-1 my-auto">{{ vorlage.vorlageTextbausteine.length }}</div>
    <div class="col-2 my-auto d-flex justify-content-end">
      <router-link :to="{name: 'DetailsVorlage', params: {vorlageid: vorlage.id.toString()}}" class="btn btn-primary btn-sm">
        <div v-if="loading && thisLoading" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

        <font-awesome-icon v-else icon="magnifying-glass-plus"/>
      </router-link>
    </div>
  </div>

  <!-- Context Menu -->
  <div
    v-if="showMenu"
    :style="{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }"
    class="dropdown-menu show"
    ref="contextMenu"
  >
    <li class="dropdown-item context-menu-item" @click="duplicateVorlage()"><font-awesome-icon icon="clone"/> Vorlage duplizieren</li>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as VorlageActionTypes } from '@/store/modules/Vorlagen/actions';

import { Vorlage } from '@/models/VorlageModels';

export default defineComponent({
  name: "VorlageRow",

  props: {
    vorlage: {
      type: Object as PropType<Vorlage>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    const thisLoading = ref(false);
    const loading = computed(() => store.getters.status.createVorlageLoading);

    const showMenu = ref(false);
    const menuPosition = ref({ x: 0, y: 0 });

    function openContextMenu (event: MouseEvent) {
      event.preventDefault();
      menuPosition.value = { x: event.clientX, y: event.clientY };
      showMenu.value = true;
      document.addEventListener('click', closeContextMenu);
    };

    function closeContextMenu () {
      showMenu.value = false;
      document.removeEventListener('click', closeContextMenu);
    };

    function duplicateVorlage () {
      thisLoading.value = true;
      const duplicate = JSON.parse(JSON.stringify(props.vorlage)) as Vorlage;

      duplicate.id = 0;
      duplicate.name += ' (Kopie)';

      for (var nummerierung of duplicate.nummerierungEinstellungen) {
        nummerierung.id = 0;
        nummerierung.vorlageid = 0;
      }

      for (var textbaustein of duplicate.vorlageTextbausteine) {
        textbaustein.id = 0;
        textbaustein.vorlageid = 0;
      }

      store.dispatch(VorlageActionTypes.CreateVorlage, duplicate)
      .then(() => {
        thisLoading.value = false;
      })
    };

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeContextMenu);
      document.removeEventListener('contextmenu', closeContextMenu);
    });

    return {
      thisLoading,
      loading,

      showMenu,
      menuPosition,
      openContextMenu,
      duplicateVorlage,
    };
  },

})
</script>

<style scoped>
.context-menu-item {
  cursor: pointer;
}
</style>