import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: 'inputField-' + _ctx.index,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["form-control", { 'is-invalid': !_ctx.isValid && _ctx.inputValue.length > 0, 'is-valid': _ctx.isValid }]),
      id: 'inputField' + _ctx.index,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
    }, null, 42, _hoisted_3), [
      [_vModelText, _ctx.inputValue]
    ]),
    (!_ctx.isValid && _ctx.inputValue.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}