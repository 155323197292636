export class Dokument {
  id: number;
  titel: string;
  kategorie: string;
  fileName: string;

  constructor() {
    this.id = 0;
    this.titel = "";
    this.kategorie = "";
    this.fileName = "";
  }
}

export interface CreateDokumentRequest {
  id: number;
  titel: string;
  kategorie: string;
  fileName: string;
  file: File | null;
}

export class DownloadDokumentRequest {
  id: number;
  fileName: string;

  constructor(id: number, filename: string) {
    this.id = id;
    this.fileName = filename;
  }
}

// export class CreateDokumentRequest {
//   id: number;
//   titel: string;
//   kategorie: string;
//   fileName: string;
//   file: File | null;

//   constructor() {
//     this.id = 0;
//     this.titel = "";
//     this.kategorie = "";
//     this.fileName = "";
//     this.file = null;
//   }
// }