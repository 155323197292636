import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Dokument, CreateDokumentRequest, DownloadDokumentRequest } from '@/models/DokumentModels';


export enum ActionTypes {
  GetDokumente = "GETDOKUMENTE",
  DownloadDokumentById = "DOWNLOADDOKUMENTBYID",
  CreateDokument = "CREATEDOKUMENT",
  UpdateDokument = "UPDATEDOKUMENT",
  DeleteDokument = "DELETEDOKUMENT",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetDokumente](context: ActionArguments): Promise<void>
  [ActionTypes.DownloadDokumentById](context: ActionArguments, data: DownloadDokumentRequest): Promise<void>
  [ActionTypes.CreateDokument](context: ActionArguments, data: CreateDokumentRequest): Promise<void>
  [ActionTypes.UpdateDokument](context: ActionArguments, data: CreateDokumentRequest): Promise<void>
  [ActionTypes.DeleteDokument](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetDokumente]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getDokumenteLoading = true;
    status.dokumenteErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetDokumente, new Array<Dokument>())

    return ApiService.getAllDokumente()
      .then(res => {
        const data: Array<Dokument> = res.data
        commit(MutationTypes.SetDokumente, data)
      })
      .catch(error => {
        status.dokumenteErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getDokumenteLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DownloadDokumentById]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.getDokumenteLoading = true;
    status.dokumenteErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.downloadDokumentById(data.id)
      .then(res => {
        console.log(res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        status.dokumenteErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getDokumenteLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateDokument]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createDokumentLoading = true;
    status.dokumenteErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createDokument(data)
      .then(res => {
        const data: Dokument = res.data
        commit(MutationTypes.AddDokument, data)
      })
      .catch(error => {
        console.log(error);
        status.dokumenteErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateDokument]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateDokumentLoading = true;
    status.dokumenteErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateDokument(data)
      .then(res => {
        const data: Dokument = res.data
        commit(MutationTypes.AddOrUpdateDokument, data)
      })
      .catch(error => {
        status.dokumenteErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteDokument]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteDokumenteLoading = true;
    status.dokumenteErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteDokument(id)
      .then(res => {
        commit(MutationTypes.DeleteDokument, id)
      })
      .catch(error => {
        console.log(error);
        status.dokumenteErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteDokumenteLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}