<template>
  <button class="btn btn-outline-secondary" @click="goBack()"><font-awesome-icon icon="chevron-left"/> Zurück</button>

  <LoadingSpinner v-if="loading" />

  <div v-if="vertrag == null" class="alert alert-danger mt-5">
    Ungültige Vorlage ID: {{ vertragid }}
  </div>


  <div v-else>
    <!-- TITEL -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1>{{ vertrag.titel }}</h1>

      <router-link :to="{name: 'EditVertrag', query: {vertragid: vertragid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <!-- BESCHREIBUNG -->

    <div class="bg-white shadow mb-4 p-2">
      <h3>Vertragslaufzeit</h3>
      <div class="row">
        <div class="col-6">
          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Vertragsbeginn
            </div>
            <div v-if="vertrag.vertragsBeginn != null" class="col">
              {{ dateToDateConverter(vertrag.vertragsBeginn) }}
            </div>
          </div>

          <div class="row">
            <div class="col-4 fw-bold">
              Vertragsende
            </div>
            <div v-if="vertrag.vertragsEnde != null" class="col">
              {{ dateToDateConverter(vertrag.vertragsEnde) }}
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4">

      <h3>Vertragspartner</h3>
      <div class="row">
        <div class="col">
          <h4>Vertragspartner 1</h4>
          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Unternehmen
            </div>
            <div class="col">
              {{ vertrag.vertragspartei1?.unternehmen }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Name
            </div>
            <div class="col">
              {{ vertrag.vertragspartei1?.vorname }} {{ vertrag.vertragspartei1?.name }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Straße
            </div>
            <div class="col">
              {{ vertrag.vertragspartei1?.strasse }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              PLZ / Ort
            </div>
            <div class="col">
              {{ vertrag.vertragspartei1?.plz }} {{ vertrag.vertragspartei1?.ort }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Land
            </div>
            <div class="col">
              {{ vertrag.vertragspartei1?.land }}
            </div>
          </div>
        </div>

        <div class="col">
          <h4>Vertragspartner 2</h4>
          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Unternehmen
            </div>
            <div class="col">
              {{ vertrag.vertragspartei2?.unternehmen }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Name
            </div>
            <div class="col">
              {{ vertrag.vertragspartei2?.vorname }} {{ vertrag.vertragspartei2?.name }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Straße
            </div>
            <div class="col">
              {{ vertrag.vertragspartei2?.strasse }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              PLZ / Ort
            </div>
            <div class="col">
              {{ vertrag.vertragspartei2?.plz }} {{ vertrag.vertragspartei2?.ort }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 fw-bold">
              Land
            </div>
            <div class="col">
              {{ vertrag.vertragspartei2?.land }}
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-if="false" class="w-50 mb-4">
      <div class="row mb-2">
        <div class="col-3 fw-bold">Nr. Ebene 1:</div>
        <div class="col">{{ nummerierung1example }}</div>
      </div>

      <div class="row mb-2">
        <div class="col-3 fw-bold">Nr. Ebene 2:</div>
        <div class="col">{{ nummerierung2example }}</div>
      </div>

      <div class="row mb-2">
        <div class="col-3 fw-bold">Mitarbeiter:</div>
        <div class="col">{{ personaldaten.vorname }} {{ personaldaten.name }}</div>
      </div>
    </div>


    <hr class="my-4">


    <!-- TEXTABSCHNITTE -->
    
    <div class="d-flex justify-content-between align-items-center">
      <h3>Abschnitte</h3>
      <!-- <button class="btn btn-primary" @click="generateDocument()"><font-awesome-icon icon="file-download" /> Dokument erstellen</button> -->
      <DownloadVertragDocumentModal :vertrag="vertrag" :personaldaten="personaldaten" />
    </div>

    <div v-if="vertrag.textabschnitte.length > 0" class="mt-3">
      <AbschnittRow v-for="abschnitt in vertrag.textabschnitte.filter((t: TextAbschnitt) => t.ownerid == null || t.ownerid == 0).sort((a: TextAbschnitt, b: TextAbschnitt) => a.position - b.position)" :key="abschnitt.id" :abschnitt="abschnitt" />
    </div>

    <hr class="my-4">

    <button class="btn btn-danger" @click="deleteVertrag()"><font-awesome-icon icon="trash" /> Vertrag löschen</button>
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import HTMLtoDOCX from 'html-to-docx';
import { useNumberConverter } from '@/utils/number_converter';
import { useDateConverter } from '@/utils/date_converter';

import { ActionTypes as VertragActionTypes } from '@/store/modules/Vertraege/actions';
import { ActionTypes as VariablenActionTypes } from '@/store/modules/Variablen/actions';
import { ActionTypes as DokumenteActionTypes } from '@/store/modules/Dokumente/actions';


import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TextbausteinRow from '@/components/Vorlagen/TextbausteinRow.vue';
import AbschnittRow from '@/components/Vertrag/AbschnittRow.vue';
import DownloadVertragDocumentModal from '@/components/Vorlagen/DownloadVertragDocumentModal.vue';

import { Vertrag, TextAbschnitt, GenerateWordDocumentRequest } from '@/models/VertragModels';
import { NummerierungEinstellung } from '@/models/VorlageModels';
import { Personaldaten } from '@/models/PersonaldatenModels';

export default defineComponent({
  name: "DetailsVertragView",

  components: {
    LoadingSpinner,
    AbschnittRow,
    TextbausteinRow,
    DownloadVertragDocumentModal
  },

  props: {
    vertragid: {
      type: String as PropType<string>,
      required: true
    },

    guid: {
      type: String as PropType<string>,
      default: "",
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.createVertragLoading)

    const { numberToRomanConverter, numberToLetterConverter } = useNumberConverter()
    const { dateToDateConverter } = useDateConverter()

    const vertrag = computed(() => store.getters.vertraege.find((v: Vertrag) => v.id == parseInt(props.vertragid)))

    const nummerierungEbene1 = ref(vertrag.value?.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == 1))
    const nummerierungEbene2 = ref(vertrag.value?.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == 2))


    const nummerierung1example = computed(() => {
      let nummerierung = "";

      if (nummerierungEbene1.value == null) {
        return nummerierung
      }

      if (nummerierungEbene1.value.praefix != "") {
        nummerierung += nummerierungEbene1.value.praefix + " "
      }

      if (nummerierungEbene1.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene1.value.darstellung == 2) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene1.value.darstellung == 3) {
        if (nummerierungEbene1.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene1.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene1.value.suffix != "") {
        nummerierung += " " + nummerierungEbene1.value.suffix
      }

      return nummerierung
    })

    const nummerierung2example = computed(() => {
      let nummerierung = "";

      if (nummerierungEbene1.value == null || nummerierungEbene2.value == null) {
        return nummerierung
      }

      if (nummerierungEbene2.value.praefix != "") {
        nummerierung += nummerierungEbene2.value.praefix + " "
      }

      if (nummerierungEbene2.value.praefixSupEbene) {
        if (nummerierungEbene1.value.darstellung == 1) {
          nummerierung += "1."
        } else if (nummerierungEbene1.value.darstellung == 2) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "I."
          } else {
            nummerierung += "i."
          }
        } else if (nummerierungEbene1.value.darstellung == 3) {
          if (nummerierungEbene1.value.gross) {
            nummerierung += "A."
          } else {
            nummerierung += "a."
          }
        }
      }

      if (nummerierungEbene2.value.darstellung == 1) {
        nummerierung += "1"
      } else if (nummerierungEbene2.value.darstellung == 2) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "I"
        } else {
          nummerierung += "i"
        }
      } else if (nummerierungEbene2.value.darstellung == 3) {
        if (nummerierungEbene2.value.gross) {
          nummerierung += "A"
        } else {
          nummerierung += "a"
        }
      }

      if (nummerierungEbene2.value.punkt) {
        nummerierung += "."
      }

      if (nummerierungEbene2.value.suffix != "") {
        nummerierung += " " + nummerierungEbene2.value.suffix
      }

      return nummerierung
    })


    const personaldaten = computed(() => store.getters.personaldaten.find((p: Personaldaten) => p.guid == props.guid) || new Personaldaten())

    const updateVertragLoading = computed(() => store.getters.status.updateVertragLoading)
    const vertragErrorMsg = computed(() => store.getters.status.vertragErrorMsg)

    const vertragEditTitel = ref(vertrag.value != null ? vertrag.value.titel : "")

    const editVertragTitelMode = ref(false)
    function toggleEditVertragTitelMode() {
      editVertragTitelMode.value = !editVertragTitelMode.value
    }

    function updateVertrag () {
      store.dispatch(VertragActionTypes.UpdateVertrag, vertrag.value)
      .then(() => {
        if (vertragErrorMsg.value == "") {
          vertragEditTitel.value = vertrag.value?.titel;
          toggleEditVertragTitelMode();
        }
      })
    }

    function deleteVertrag() {
      store.dispatch(VertragActionTypes.DeleteVertrag, parseInt(props.vertragid))
      .then(() => {
        goBack()
      })
    }

    function goBack() {
      if (props.guid != "") {
        router.push({ name: "DetailsPersonaldaten", params: { guid: props.guid }});
      }
      else {
        router.push({ name: "Vertraege" });
      }
    }

    const documentOptions = {
      orientation: 'portrait', // or 'landscape' if needed
      pageSize: {
        width: 11906, // DIN A4 width in TWIP (8.27 inches)
        height: 16838, // DIN A4 height in TWIP (11.69 inches)
      },
      margins: {
        top: 1440, // Default: 1 inch margin (1440 TWIP)
        right: 1800, // Default: 1.25 inch margin (1800 TWIP)
        bottom: 1440, // Default: 1 inch margin (1440 TWIP)
        left: 1800, // Default: 1.25 inch margin (1800 TWIP)
        header: 720, // Default header margin
        footer: 720, // Default footer margin
        gutter: 0, // No extra gutter margin
      },
      title: 'My Document Title',
      subject: 'Document Subject',
      creator: 'Your Name or App Name', // Change to the creator's name
      keywords: ['html-to-docx', 'document', 'export'],
      description: 'A description of the document',
      lastModifiedBy: 'Your Name or App Name', // Change to the modifier's name
      revision: 1,
      createdAt: new Date(),
      modifiedAt: new Date(),
      headerType: 'default',
      footerType: 'default',
      header: true, // Set to true to enable header
      footer: true, // Set to true to enable footer
      font: 'Roboto', // You can choose a different font if needed
      fontSize: 22, // Default font size in HIP (Half of Point)
      pageNumber: true, // Enable page numbers in footer
      lineNumber: false, // Disable line numbering
      lang: 'de-DE', // Language for spell checker
    };


    return {
      store,
      router,

      dateToDateConverter,

      loading,

      numberToLetterConverter,
      numberToRomanConverter,

      vertrag,
      nummerierung1example,
      nummerierung2example,

      personaldaten,
      updateVertragLoading,
      vertragErrorMsg,
      vertragEditTitel,
      editVertragTitelMode,
      toggleEditVertragTitelMode,

      updateVertrag,
      deleteVertrag,

      goBack,

      documentOptions
    }
  },

  methods: {
    docTitelDateConverter (date: Date): string {
      return date.getFullYear()  + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
    },

    async generateDocument () {
      // Get the HTML content to be converted to PDF
      let htmlContent = "<div>";

      for (var abschnitt of this.vertrag.textabschnitte) {
        console.log(abschnitt);
        htmlContent += '<div>'
        // htmlContent += '<h2>' + this.createSectionHeading(abschnitt, 1) + '</h2>'
        htmlContent += '<h1>' + abschnitt.titel + '</h1>'
        htmlContent += '<p>' + this.replaceVariablesInText(abschnitt) + '</p>'

        if (abschnitt.abschnitte != null && abschnitt.abschnitte.length > 0) {
          for (var subAbschnitt of abschnitt.abschnitte) {
            // htmlContent += '<h3>' + this.createSectionHeading(subAbschnitt, 2) + '</h3>' + '<p>' + this.replaceVariablesInText(subAbschnitt) + '</p>'
            htmlContent += '<h2>' + subAbschnitt.titel + '</h2>' + '<p>' + this.replaceVariablesInText(subAbschnitt) + '</p>'
          }
        }

        htmlContent += '</div>'
      }

      htmlContent += "</div>";

      const fileName = this.personaldaten?.vorname + "_" + this.personaldaten?.name + "_" + this.personaldaten?.guid + "_" + this.vertrag?.titel + "_" + this.docTitelDateConverter(new Date(this.vertrag?.adate)) + '.docx';

      await this.store.dispatch(VertragActionTypes.GenerateWordDocument, new GenerateWordDocumentRequest(htmlContent, null, fileName))

      // try {
      //   const headerHTMLString = 'Header content here';
      //   const footerHTMLString = '';
      //   const docxArrayBuffer = await HTMLtoDOCX(htmlContent, headerHTMLString, this.documentOptions, footerHTMLString);

      //   const blob = new Blob([docxArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      //   const url = URL.createObjectURL(blob);

      //   const a = document.createElement('a');
      //   a.href = url;
      //   a.download = this.personaldaten?.vorname + "_" + this.personaldaten?.name + "_" + this.personaldaten?.guid + "_" + this.vertrag?.titel + "_" + this.docTitelDateConverter(new Date(this.vertrag?.adate)) + '.docx';
      //   a.click();

      //   URL.revokeObjectURL(url);
      // } catch (error) {
      //   console.error('Error generating DOCX:', error);
      // }
    },

    createSectionHeading(abschnitt: TextAbschnitt, ebene: number): string {
      const nummerierungSetting = this.vertrag.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == ebene);
      let heading = "";

      if (nummerierungSetting == null) {
        return abschnitt.titel
      }

      if (nummerierungSetting.praefix != "") {
        heading += nummerierungSetting.praefix + " "
      }

      if (ebene == 2 && nummerierungSetting.praefixSupEbene) {
        const ebene1Setting = this.vertrag.nummerierungEinstellungen.find((n: NummerierungEinstellung) => n.ebene == 1);
        if (ebene1Setting != null) {
          if (ebene1Setting.darstellung == 1) {
            heading += "1."
          } else if (ebene1Setting.darstellung == 2) {
            if (ebene1Setting.gross) {
              heading += "I."
            } else {
              heading += "i."
            }
          } else if (ebene1Setting.darstellung == 3) {
            if (ebene1Setting.gross) {
              heading += "A."
            } else {
              heading += "a."
            }
          }
        }
      }

      if (nummerierungSetting.darstellung == 1) {
        heading += abschnitt.position
      }
      else if (nummerierungSetting.darstellung == 2) {
        let roman = this.numberToRomanConverter(abschnitt.position)
        if (!nummerierungSetting.gross) {
          roman = roman.toLowerCase();
        }

        heading += roman;
      }
      else if (nummerierungSetting.darstellung == 3) {
        let letter = this.numberToLetterConverter(abschnitt.position)
        if (!nummerierungSetting.gross) {
          letter = letter.toLowerCase();
        }

        heading += letter;
      }

      if (nummerierungSetting.punkt) {
        heading += "."
      }

      heading += " " + abschnitt.titel

      if (nummerierungSetting.suffix != "") {
        heading += " " + nummerierungSetting.suffix
      }

      return heading
    },

    replaceVariablesInText(abschnitt: TextAbschnitt): string {
      let response = abschnitt.text;
      
      for (let input of abschnitt.inputs) {
        if (input.value != null && input.value != "") {
          let textKey = input.key.replace("<<", "&lt;&lt;")
          textKey = textKey.replace(">>", "&gt;&gt;")
          response = response.replace(textKey, input.value)
        }
      }

      return response;
    }
  },

  mounted () {
    if (this.store.getters.variablen.length == 0) {
      this.store.dispatch(VariablenActionTypes.GetVariablen, undefined)
    }

    this.store.dispatch(DokumenteActionTypes.GetDokumente, undefined)
  }
})
</script>